import React, { useState, useCallback, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Follower, Following, User } from '../model/types'
import UserAvatar from 'react-user-avatar'
import api from '../model/ApiService'
import { isSuccess } from '../model/Util'
import useReloadUser from '../redux/useReloadUser'

const UserRow: React.FC<{ user: Follower | Following; button?: React.ReactNode; type: 'followers' | 'following' }> = (props) => {
  const { user, button, type } = props

  let userData: User
  if (type == 'followers') {
    userData = (user as Follower)._follower
  } else {
    userData = (user as Following)._account
  }

  return (
    <div className='d-flex flex-row align-items-center'>
      <a className='d-flex flex-row align-items-center text-dark' href={`/user/${userData.username}`}>
        <UserAvatar size='32' className='align-middle my-2 mr-3' name={userData.firstName + ' ' + userData.lastName} src={userData.imageUrl} />
        <div>{userData.firstName + ' ' + userData.lastName}</div>
      </a>
      <div className='flex-fill' />
      {button}
    </div>
  )
}

export type FollowersFollowingModalProps = {
  show: boolean
  dismiss: () => void
  type: 'followers' | 'following'
}

const FollowersFollowingModal: React.FC<FollowersFollowingModalProps> = (props) => {
  const { show, dismiss, type } = props

  const [users, setUsers] = useState<{ users: (Follower | Following)[]; hasMore: boolean }>({ users: [], hasMore: true })
  const [pending, setPending] = useState<{ users: (Follower | Following)[]; hasMore: boolean }>({ users: [], hasMore: true })
  const [isLoading, setIsLoading] = useState({ pending: false, accepted: false })
  const [toggle, setToggle] = useState(false)

  const reloadUser = useReloadUser()

  const fetchMore = useCallback(
    async (overwrite = false) => {
      if (!overwrite && (!users.hasMore || isLoading.accepted)) {
        return
      }

      setIsLoading({ ...isLoading, accepted: true })

      if (type == 'followers') {
        const resp = await api.getFollowers('approved', overwrite ? 0 : users.users.length)
        if (isSuccess(resp.status) && resp.data.followers) {
          if (overwrite) {
            setUsers({ users: resp.data.followers, hasMore: resp.data.followers.length == 9 })
          } else {
            setUsers({ users: [...users.users, ...resp.data.followers], hasMore: resp.data.followers.length == 9 })
          }
        }
      } else {
        const resp = await api.getFollowing('approved', overwrite ? 0 : users.users.length)
        //console.log(resp)
        if (isSuccess(resp.status) && resp.data.following) {
          if (overwrite) {
            setUsers({ users: resp.data.following, hasMore: resp.data.following.length == 9 })
          } else {
            setUsers({ users: [...users.users, ...resp.data.following], hasMore: resp.data.following.length == 9 })
          }
        }
      }
      setIsLoading({ ...isLoading, accepted: false })
    },
    [users, type]
  )

  const fetchMorePending = useCallback(
    async (overwrite = false) => {
      if (!overwrite && (!pending.hasMore || isLoading.pending)) {
        return
      }

      setIsLoading({ ...isLoading, pending: true })

      if (type == 'followers') {
        const pendingResp = await api.getFollowers('pending', overwrite ? 0 : pending.users.length)
        if (isSuccess(pendingResp.status) && pendingResp.data.followers) {
          if (overwrite) {
            setPending({
              users: pendingResp.data.followers,
              hasMore: pendingResp.data.followers.length == 9,
            })
          } else {
            setPending({
              users: [...pending.users, ...pendingResp.data.followers],
              hasMore: pendingResp.data.followers.length == 9,
            })
          }
        }
      } else {
        const pendingResp = await api.getFollowing('pending', overwrite ? 0 : pending.users.length)
        if (isSuccess(pendingResp.status) && pendingResp.data.following) {
          if (overwrite) {
            setPending({
              users: pendingResp.data.following,
              hasMore: pendingResp.data.following.length == 9,
            })
          } else {
            setPending({
              users: [...pending.users, ...pendingResp.data.following],
              hasMore: pendingResp.data.following.length == 9,
            })
          }
        }
      }
      setIsLoading({ ...isLoading, pending: true })
    },
    [pending, type]
  )

  // Load when modal is shown
  useEffect(() => {
    if (!show) {
      return
    }

    fetchMore(true)
    fetchMorePending(true)
  }, [show, type, toggle])

  return (
    <Modal show={show} onHide={dismiss} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='small font-weight-bold'>
            <i className='fa fa-users text-dark mr-2' />
            {type == 'followers' ? 'Followers' : 'Following'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {pending.users.length > 0 && (
          <>
            <div>
              <div className='text-muted font-weight-bold small mb-1'>Pending</div>
              {pending.users.map((user) => {
                const button =
                  type == 'followers' ? (
                    <>
                      <button
                        className='btn btn-light btn-sm mr-2'
                        onClick={async () => {
                          await api.setFollowRequestStatus((user as Follower)._id, false)
                          setToggle(!toggle)
                          reloadUser()
                        }}>
                        Cancel
                      </button>
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={async () => {
                          await api.setFollowRequestStatus((user as Follower)._id, true)
                          setToggle(!toggle)
                          reloadUser()
                        }}>
                        Approve
                      </button>
                    </>
                  ) : (
                    <button
                      className='btn btn-light btn-sm'
                      onClick={async () => {
                        await api.unfollowUser((user as Following)._account._id)
                        setToggle(!toggle)
                      }}>
                      Cancel
                    </button>
                  )
                return <UserRow user={user} key={user._id} button={button} type={type} />
              })}
            </div>
            <hr className='my-3' />
          </>
        )}

        <div className=''>
          {users.users.map((user) => {
            const button =
              type == 'followers' ? null : (
                <button
                  className='btn btn-light btn-sm'
                  onClick={() => {
                    api.unfollowUser((user as Following)._account._id)
                    setToggle(!toggle)
                  }}>
                  Unfollow
                </button>
              )
            return <UserRow user={user} key={user._id} button={button} type={type} />
          })}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FollowersFollowingModal
