import React, { useState } from 'react'
import Moment from 'moment'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Img } from 'react-image'
import Dotdotdot from 'react-dotdotdot'
import { connect } from 'react-redux'
import AspectRatio from 'react-aspect-ratio'

import * as lib from '../model/Utils'
import { Redirect } from 'react-router-dom'
import { ProjectPropType, UserPropType } from '../model/CustomPropTypes'
import { Button } from 'react-bootstrap'

function SmartPostCard({ user, membershipsHash, post, showUpdated }) {
  // console.log(post)
  const { _id, title, imageUrl, shortName, distanceFromUser, isCompleted, isEvent, isNational, eventDate, _organizationId, lastModified } = post

  // Used to trigger a redirect upon project join
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const isMember = !!membershipsHash[_id]

  const lastModifiedFormatted = lib.getFormattedDate(Moment.utc(lastModified).local())
  const eventDateFormatted = isEvent ? lib.formatDate(eventDate) : null
  const resizedImageUrl = imageUrl ? lib.getResizedUrl(imageUrl) : null

  const join = async () => {
    const data = { postId: _id.trim(), organizationId: _organizationId }
    const resp = await axios.post('/api/v2/member', data)

    if (resp.data.success) {
      setShouldRedirect(true)
    } else {
      console.log('Error joining project')
    }
  }

  if (shouldRedirect) {
    return <Redirect push to={`/${shortName}`} />
  }

  const isLoggedIn = !!user?._id

  return (
    <div
      id='postcard'
      className='card rounded h-100 grow'
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}>
      <div style={{ aspectRatio: '2 / 1', overflow: 'hidden' }}>
        {/* <AspectRatio ratio='4/3'> */}
        <a href={`/${shortName}`} className='w-100 h-100'>
          {/* <AspectRatio ratio='4/3' style={{ overflow: 'hidden' }}> */}
          <Img
            src={[resizedImageUrl, imageUrl, '/img/loadingImage.svg']}
            loader={<Img src='/img/loadingImage.svg' />}
            className='w-100 h-100 object-fit-cover'
          />
          {/* </AspectRatio> */}
        </a>

        <div
          className='position-absolute w-100 h-auto d-flex align-items-end justify-content-center'
          style={{ left: '0', top: '0', pointerEvents: 'none', aspectRatio: '2 / 1' }}>
          {!isMember && isLoggedIn && (
            <div className={`w-100 ${isHovered ? 'animate__animated animate__fadeInUp animate__faster blur-behind ' : 'd-none'}`}>
              <div className='d-flex flex-column w-100'>
                <Button
                  variant='light'
                  onClick={join}
                  className='rounded-0 border-0 bg-light-translucent'
                  style={{ pointerEvents: 'auto' }}
                  disabled={isCompleted || !isLoggedIn}>
                  <i className='fa fa-plus mr-2' />
                  {isEvent ? 'Follow Event' : 'Follow Project'}
                </Button>
              </div>
            </div>
          )}
        </div>
        {/* </AspectRatio> */}
      </div>

      <a href={`/${shortName}`} className='flex-fill d-flex flex-column bg-white' style={{ zIndex: 10 }}>
        <div className='card-body p-2 col flex-fill d-flex flex-column'>
          <div className='card-text'>
            {isEvent && (
              <div className='text-secondary small'>
                <i className='fa fa-calendar mr-1' />
                {eventDateFormatted}
              </div>
            )}
            <div className='row no-gutters'>
              <div className='col-12' style={{ fontSize: 18 }}>
                <Dotdotdot clamp={2} className='text-dark'>
                  {title}
                </Dotdotdot>
              </div>
            </div>
          </div>
          {/* Details section */}
          <div className='flex-grow-1' />
          <div className='card-text mt-2 d-flex align-items-end'>
            <div>
              <div className='d-inline-block'>
                {isCompleted && <div className='text-muted small font-weight-bold'>This project has ended</div>}

                {_organizationId && <div className='text-muted small'>{_organizationId.name}</div>}

                {showUpdated && (
                  <div className='text-muted small'>
                    <i className='fa fa-clock-o mr-1' />
                    Updated {lastModifiedFormatted}
                  </div>
                )}

                {isNational ? (
                  <div className='text-secondary small'>
                    <span role='img' aria-label='American Flag'>
                      🇺🇸
                    </span>{' '}
                    National {isEvent ? 'Event' : 'Project'}
                  </div>
                ) : (
                  distanceFromUser && (
                    <div className='text-secondary small'>
                      <i className='fa fa-map-marker mr-1' />
                      {distanceFromUser} miles away
                    </div>
                  )
                )}
              </div>
            </div>
            <div className='flex-fill' />
          </div>
        </div>
        {isMember && (
          <div className='position-absolute' style={{ bottom: 0, right: 0 }}>
            <img src='/img/joined_badge.png' width='30rem' height='30rem' alt='joined indicator' />
          </div>
        )}
      </a>
    </div>
  )
}

SmartPostCard.propTypes = {
  post: ProjectPropType,
  user: UserPropType,
  membershipsHash: PropTypes.any,
  showUpdated: PropTypes.bool,
}

const mapStateToProps = (state) => ({ user: state.user, membershipsHash: state.activeMembershipsHash })

export default connect(mapStateToProps)(SmartPostCard)
