import React, { useCallback, useEffect, useState } from 'react'
import { FeedItem, Project, TextPost } from '../model/types'

import SmartPostCard from './SmartPostCard'
import TextPostContent from './TextPostContent'

export type FeedItemBoxProps = {
  item: FeedItem
  nearbyProjects?: Project[]
  recommendedProjects?: Project[]
  didDeletePost: (id: string) => void
  didEditPost: (post: TextPost) => void
  isFirst?: boolean
  showInModal?: (post: TextPost) => void
}

const FeedItemBox: React.FC<FeedItemBoxProps> = (props) => {
  const { item, nearbyProjects, recommendedProjects, didDeletePost, didEditPost, isFirst, showInModal } = props

  //console.log(item)

  if (item.type == 'textpost') {
    const post = item.item as TextPost
    return (
      <TextPostContent
        post={post}
        isFirst={isFirst}
        didDeletePost={didDeletePost}
        didEditPost={didEditPost}
        showInModal={
          showInModal
            ? () => {
                showInModal(post)
              }
            : undefined
        }
      />
    )
  }

  if (item.type == 'recommended-projects') {
    const pageNumber = item.item as number
    const startIndex = pageNumber * 3
    const slice = (recommendedProjects ?? []).slice(startIndex, startIndex + 3)

    if (slice.length == 0) {
      return null
    }

    return (
      <div className='row card rounded-lg mt-3 pt-3 d-flex flex-column'>
        <div className='font-weight-bold px-3'>Recommended For You</div>
        <div className='small px-3' style={{ color: '#BBB' }}>
          Based on your interests and organizations you follow.
        </div>
        <div className='col'>
          <div className='row px-3 pb-3 my-1 overflow-auto hide-scrollbar'>
            <ProjectsCarousel projects={slice} />
          </div>
        </div>
      </div>
    )
  }

  if (item.type == 'nearby-projects') {
    const pageNumber = item.item as number
    const startIndex = pageNumber * 3
    const slice = (nearbyProjects ?? []).slice(startIndex, startIndex + 3)

    if (slice.length == 0) {
      return null
    }

    return (
      <div className='row card rounded-lg mt-3 pt-3 d-flex flex-column overflow-auto'>
        <div className='font-weight-bold px-3'>Projects Near You</div>
        <div className='small px-3' style={{ color: '#BBB' }}>
          Based on your ZIP code.
        </div>
        <div className='col'>
          <div className='row px-3 pb-3 my-1 overflow-auto hide-scrollbar'>
            <ProjectsCarousel projects={slice} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='row card rounded-lg mt-3 p-5 row'>
      <div className='d-flex flex-row mx-auto'>
        <i className='fa fa-check-circle fa-3x text-primary mr-3' />
        <div className='d-flex flex-column'>
          <div className='font-weight-bold'>You've reached the end.</div>
          <div className='text-muted small'>Follow users or join orgs to get more posts.</div>
        </div>
      </div>
    </div>
  )
}

const ProjectsCarousel: React.FC<{ projects: Project[] }> = (props) => {
  const { projects } = props

  return (
    <div className='d-flex flex-row px-1 mt-2'>
      {projects.map((project) => (
        <div className='mr-3' style={{ width: 300 }} key={project._id}>
          <SmartPostCard post={project} />
        </div>
      ))}
    </div>
  )
}

export default FeedItemBox
