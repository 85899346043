import React, { useEffect, useState, useCallback } from 'react'

import { Modal, Button, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import axios, { AxiosRequestConfig } from 'axios'

import { NewTextPost, Project, ProjectsState, RootState, TextPost } from '../model/types'
import { isSuccess } from '../model/Util'
import api from '../model/ApiService'
import { ErrorResponse, LoadFollowedProjectsResponse } from '../model/response-types'
import CompactProjectCard from './CompactProjectCard'

export type CreateTextPostModalProps = {
  dismiss: () => void
  save: () => void
  show: boolean
  editingPost?: TextPost
  attachingProject?: Project
}

const CreateTextPostModal: React.FC<CreateTextPostModalProps> = (props) => {
  const { dismiss, save, show, editingPost, attachingProject } = props
  const user = useSelector((state: RootState) => state.user)

  const [newPostText, setNewPostText] = useState('')
  const [organizationId, setOrganizationId] = useState('Public')
  const [privateToOrg, setPrivateToOrg] = useState(false)
  const [changeOrgDisabled, setChangeOrgDisabled] = useState({ orgId: false, privateToOrg: false })
  const [selectedProject, setSelectedProject] = useState<Project | undefined>(undefined)
  const [showSelectProject, setShowSelectProject] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [existingImageUrl, setExistingImageUrl] = useState<string | undefined>(undefined)

  const [isPosting, setIsPosting] = useState(false)
  const [uploadProgress, setUploadProgress] = useState<string | null>(null)
  const [errors, setErrors] = useState<Array<ErrorResponse>>([])

  const [followedProjects, setFollowedProjects] = useState<ProjectsState>({ projects: [], hasMore: true })

  const loadMoreProjects = async () => {
    const moreProjects = await await axios.get<LoadFollowedProjectsResponse>(`/api/v2/memberships?skipItems=${followedProjects.projects.length}`)
    if (moreProjects.data.success) {
      if (moreProjects.data.data && moreProjects.data.data.length > 0) {
        setFollowedProjects({ projects: followedProjects.projects.concat(moreProjects.data.data.map((post) => post._post)), hasMore: true })
      } else {
        setFollowedProjects({ ...followedProjects, hasMore: false })
      }
    }
  }

  // Load initial followed projects
  useEffect(() => {
    loadMoreProjects()
  }, [])

  // Parse props
  useEffect(() => {
    if (editingPost) {
      setNewPostText(editingPost.text)
      setOrganizationId(editingPost._organization?._id ?? 'Public')
      setPrivateToOrg(editingPost.privateToOrg)
      setSelectedProject(editingPost._post)
      setFile(null)
      setExistingImageUrl(editingPost.imageUrl)
    }
    if (attachingProject) {
      setSelectedProject(attachingProject)
    }
  }, [editingPost, attachingProject])

  // If there's a project selected with an org, we have to use that org
  useEffect(() => {
    if (selectedProject?._organizationId && user?.activeOrganizations?.find((org) => selectedProject?._organizationId._id === org._organization._id)) {
      //console.log(user?.activeOrganizations?.find((org) => selectedProject?._organizationId._id === org._organization._id))
      //console.log(selectedProject?._organizationId)
      setOrganizationId(selectedProject._organizationId._id)
      setPrivateToOrg(selectedProject.isPrivateForOrganization ?? false)
      setChangeOrgDisabled({ orgId: true, privateToOrg: selectedProject.isPrivateForOrganization ?? false })
    } else {
      setOrganizationId('Public')
      setPrivateToOrg(false)
      setChangeOrgDisabled({ orgId: false, privateToOrg: false })
    }
  }, [selectedProject, user])

  /**
   * Stores the information for the new text post to be created
   */
  const newPost: NewTextPost = {
    text: newPostText,
    organizationId: organizationId == 'Public' ? undefined : organizationId,
    privateToOrg,
    postId: selectedProject?._id,
    imageUrl: undefined,
  }

  /**
   * Callback that's called when the "Post" button is clicked
   */
  const postNewPost = useCallback(async () => {
    setIsPosting(true)

    const finishPosting = async () => {
      //console.log(newPost)
      const resp = await api.createTextPost(newPost, editingPost?._id)
      setIsPosting(false)
      if (!resp) {
        return
      }
      if (!isSuccess(resp.status) || resp.data.errors) {
        setErrors(resp.data.errors ?? [])
        console.log(resp.data.errors)
      } else {
        setNewPostText('')
        setSelectedProject(undefined)
        setOrganizationId('Public')
        save()
      }
    }

    if (file) {
      // Upload the file first
      const resp = await api.getImageUploadRequest(file.name, file.type)
      const options: AxiosRequestConfig = {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          setUploadProgress(`Uploading ${Math.round((progressEvent.loaded * 100) / progressEvent.total)}%`)
        },
      }
      try {
        await axios.put(resp.data.signedRequest, file, options)
        setUploadProgress('Optimizing...')
        setTimeout(() => {
          setUploadProgress(null)
          setFile(null)

          newPost.imageUrl = resp.data.url
          finishPosting()
        }, 5000)
      } catch (error) {
        setErrors([...errors, { field: 'imageUpload', message: (error as Error)?.message }])
        setUploadProgress(null)
        setIsPosting(false)
        console.log(error)
        return
      }
    } else if (existingImageUrl) {
      // We have an image url from editing a post
      newPost.imageUrl = existingImageUrl
      finishPosting()
    } else {
      finishPosting()
    }
  }, [newPost])

  /**
   * Callback that's called when an image is uploaded from the file picker
   */
  const changeFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.currentTarget) {
      return
    }
    const files = (e.currentTarget as HTMLInputElement).files
    if (!files) {
      return
    }
    setFile(files[0])
    setExistingImageUrl(undefined)
  }, [])

  let previewImg = null
  if (existingImageUrl) {
    previewImg = existingImageUrl
  } else if (file) {
    previewImg = URL.createObjectURL(file)
  }

  const allValid = newPostText.length > 0 || previewImg || selectedProject

  return (
    <Modal show={show} onHide={dismiss} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='small font-weight-bold'>
            <i className='fa fa-pencil text-dark mr-2' />
            Post to your feed
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          value={newPostText}
          onChange={(e) => {
            setNewPostText(e.target.value)
          }}
          placeholder={`How are you making a difference today, ${user.firstName}?`}
          className='form-control border-0 bg-light inset-shadow'
          rows={6}
        />
        {previewImg && (
          <div className='position-relative mt-3'>
            <img src={previewImg} alt='preview' className='img-fluid rounded card-shadow positon-absolute' />
            <div id='image-actions' className='position-absolute p-3 d-flex flex-row' style={{ top: 0, right: 0 }}>
              <label className='cursor-pointer m-0 flex-fill position-relative'>
                <input className='d-none' type='file' accept='image/*' onChange={changeFile} disabled={false} />
                <div className='btn btn-light'>
                  <i className='fa fa-photo fa-lg text-success mr-2' />
                  Change Photo
                </div>
              </label>
              <button
                type='button'
                className='closeButton ml-2'
                onClick={() => {
                  setFile(null)
                  setExistingImageUrl(undefined)
                }}
                aria-label='Cancel'
                style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
          </div>
        )}
        {showSelectProject && (
          <div className='mt-3 mb-4'>
            <div className='d-flex flex-row align-items-center mb-1'>
              <div className='font-weight-bold'>Select Project to Attach</div>
              <div className='flex-fill' />
              <button
                className='btn btn-link small'
                onClick={() => {
                  setShowSelectProject(false)
                }}>
                Cancel
              </button>
            </div>
            <div className=' inset-shadow p-2 rounded-lg bg-light overflow-auto' style={{ height: 200 }}>
              {followedProjects.projects.map((project) => (
                <div className='mb-2' key={project._id}>
                  <CompactProjectCard
                    project={project}
                    secondaryCompact
                    onClick={() => {
                      setSelectedProject(project)
                      setShowSelectProject(false)
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {selectedProject && (
          <div className='mt-3 mb-4 position-relative'>
            {/* <div className='d-flex flex-row align-items-center mb-1'> */}
            {/* <div className='font-weight-bold'>Attached Project</div> */}
            {/* <div className='flex-fill' /> */}
            {/* <button className='btn btn-link small p-0 pr-1' onClick={() => { setSelectedProject(undefined) }}>&times;</button> */}
            {/* </div> */}
            <div className='pt-3 pr-3'>
              <CompactProjectCard project={selectedProject} secondaryCompact onClick={() => {}} />
            </div>
            <button
              type='button'
              onClick={() => {
                setSelectedProject(undefined)
              }}
              className='closeButton small position-absolute card-shadow ml-2'
              aria-label='Cancel'
              style={{ top: 0, right: 0 }}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        )}
        <div className='d-flex flex-row mt-3'>
          {!showSelectProject && !selectedProject && (
            <button
              className={`btn btn-light flex-fill py-2 ${!file && 'mr-2'}`}
              onClick={() => {
                setShowSelectProject(true)
              }}>
              <i className='fa fa-link fa-lg text-primary mr-2' />
              Link Project
            </button>
          )}
          {!file && (
            <label className={`cursor-pointer m-0 ${!showSelectProject && !selectedProject && 'ml-2'} flex-fill`}>
              <input className='d-none' type='file' accept='image/*' onChange={changeFile} disabled={false} />
              <div className='btn btn-light w-100 py-2'>
                <i className='fa fa-photo fa-lg text-success mr-2' />
                Attach Photo
              </div>
            </label>
          )}
        </div>

        <div className='font-weight-bold mb-2 mt-3'>Organization</div>
        <select
          className='custom-select'
          id='inputGroupSelect'
          value={organizationId}
          disabled={changeOrgDisabled.orgId}
          onChange={(e) => {
            setOrganizationId(e.target.value)
          }}>
          <option key='Public' value='Public'>
            None (Public)
          </option>
          {(user.activeOrganizations ?? []).map((org) => (
            <option key={org._organization.shortName} value={org._organization._id}>
              {org._organization.name}
            </option>
          ))}
        </select>

        {organizationId !== 'Public' && (
          <div>
            <input
              id='isPrivate'
              type='checkbox'
              name='isPrivate'
              value='isPrivate'
              className='mt-3'
              onChange={(e) => {
                setPrivateToOrg(e.currentTarget.checked)
              }}
              checked={privateToOrg}
            />
            <label className='ml-2 small text-muted' htmlFor='isPrivate'>
              {' '}
              Only show this post to members of the organization.
            </label>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='small text-muted text-align-left w-100'>
          {privateToOrg
            ? 'Your post will only be shown to members of the organization'
            : 'Your post will be shown to mutual members of your organizations and people who follow you.'}
        </div>
        <Button variant='primary' onClick={postNewPost} className='w-100 py-3' disabled={isPosting || !allValid || showSelectProject}>
          {uploadProgress ? (
            <span>{uploadProgress}</span>
          ) : isPosting ? (
            <Spinner animation='border' variant='light' role='status' />
          ) : (
            <span>
              <i className='fa fa-pencil mr-2' />
              Post
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateTextPostModal
