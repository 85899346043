import React from 'react'
import PropTypes from 'prop-types'

import SmartPostCard from './SmartPostCard'

/**
 * Displays a list of project cards.
 */
function ProjectsList ({ projects, emptyMessage, isLoaded = true }) {
  if (!isLoaded) {
    // Just return blank, so it doesn't flicker
    return <div className='row mt-3' />
  }

  return (
    <div className='row mt-3'>
      {
        projects && projects.length > 0 ? (
          projects.map(post => {
            return (
              <div className='col-sm-12 col-md-6 col-lg-4 mx-auto mb-4' key={post._id}>
                <SmartPostCard post={post} />
              </div>
            )
          })
        ) : (
          <div className='col'>
            <h6 className='mt-3'>{emptyMessage || 'There\'s nothing here, but check back soon!'}</h6>
          </div>
        )
      }
    </div>
  )
}

ProjectsList.propTypes = {
  projects: PropTypes.array,
  emptyMessage: PropTypes.string,
  isLoaded: PropTypes.bool
}

export default ProjectsList
