import React from 'react'
import Moment from 'moment'
import UserAvatar from 'react-user-avatar'
import PropTypes from 'prop-types'
import Linkify from 'linkifyjs/react'
import reactStringReplace from 'react-string-replace'
import axios from 'axios'
import { MentionsInput, Mention as UserMention } from 'react-mentions'
import { merge } from 'lodash'
import defaultCommentInputStyle from '../../styles/defaultCommentInputStyle'
import defaultMentionStyle from '../../styles/defaultMentionStyle'
import { toast } from 'react-toastify'
import defaultCommentInputSuccessStyle from '../../styles/defaultCommentInputSuccessStyle'
import { Link } from 'react-router-dom'
import PlainButton from '../PlainButton'

class Comment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showReply: false,
      replyComment: {
        value: '',
        valid: false,
      },
      plainTextReplyComment: {
        value: '',
        valid: false,
      },
      mentions: [],
      isLikeable: this.props.handleIsLikeable(this.props.comment._id),
      likesCount: this.props.comment.likesCount,
    }
    this.toggleReplyTextAreaVisible = this.toggleReplyTextAreaVisible.bind(this)
    this.handleCommentChange = this.handleCommentChange.bind(this)
    this.handleDeleteCommentFromServer = this.handleDeleteCommentFromServer.bind(this)
    this.handleToggleLikeComment = this.handleToggleLikeComment.bind(this)
    this.handleReportComment = this.handleReportComment.bind(this)
    this.handleMuteUser = this.handleMuteUser.bind(this)
  }

  handleReportComment(comment) {
    console.log('Report comment ' + comment._id)
    const data = {
      postId: comment._post,
      commentId: comment._id,
      commentAuthor: comment._creator._id,
    }
    axios
      .post('/api/v2/me/reportComment', data)
      .then(function (response) {
        if (response.data.success) {
          toast.success('Reported content to Administrator for review', {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          })
        } else {
          const error = response.data.error ? response.data.error : 'Error reporting comment'
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  handleMuteUser(comment) {
    console.log('Mute User ' + comment._creator._id)
    const data = {
      userToMute: comment._creator._id,
      shouldMute: true,
    }
    axios
      .post('/api/v2/me/mute', data)
      .then(
        function (response) {
          if (response.data.success) {
            console.log('User muted: ' + comment._creator.username)
            this.props.handleMuteUser()
          } else {
            toast.error('Failed to mute ' + comment._creator.username, {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
            })
          }
        }.bind(this)
      )
      .catch(function (error) {
        console.log(error)
      })
  }

  handleToggleLikeComment(comment) {
    const newStatus = this.state.isLikeable
    const data = {
      commentId: comment._id,
      status: newStatus,
    }

    if (this.props.type === 'post') {
      data['postId'] = this.props.postId
    } else if (this.props.type === 'textpost') {
      data['textPostId'] = this.props.postId
    } else {
      return
    }

    axios
      .post(`/api/v2/comment/like/${this.props.type}`, data)
      .then(
        function (response) {
          if (response.data.success) {
            this.setState({ isLikeable: !newStatus, likesCount: response.data.likesCount })
          } else {
            console.log('Server error. Could not set like status')
          }
        }.bind(this)
      )
      .catch(function (error) {
        console.log(error)
      })
  }

  handleDeleteCommentFromServer(comment) {
    axios
      .delete(`/api/v2/comment/delete/${comment._id}`)
      .then(
        function (response) {
          if (response.data.success) {
            console.log('Deleted comment ' + comment._id)
            this.props.handleDeleteComment(comment)
          }
        }.bind(this)
      )
      .catch(function (error) {
        console.log(error)
      })
  }

  handleSubmitComment(parentId, plainTextReplyComment) {
    if (!plainTextReplyComment.valid) {
      return
    }
    const data = {
      userId: this.props.userId,
      parentId: parentId,
      text: plainTextReplyComment.value.trim(),
      mentions: this.state.mentions,
    }

    if (this.props.type === 'post') {
      data['postId'] = this.props.postId
    } else if (this.props.type === 'textpost') {
      data['textPostId'] = this.props.postId
    } else {
      return
    }

    axios
      .post(`/api/v2/comment/${this.props.type}`, data)
      .then(
        function (response) {
          //(response.data)
          this.props.handleAddComment(response.data.data)
          this.setState({
            showReply: false,
            replyComment: {
              value: '',
              valid: false,
            },
          })
        }.bind(this)
      )
      .catch(function (error) {
        console.log(error)
      })
  }

  toggleReplyTextAreaVisible() {
    this.setState({ showReply: !this.state.showReply })
  }

  handleCommentChange(e, newValue, newPlainTextValue, mentions) {
    this.setState({
      replyComment: {
        value: newValue,
        valid: newValue.length > 0 && newValue.length <= 2000,
      },
      plainTextReplyComment: {
        value: newPlainTextValue,
        valid: newPlainTextValue.length > 0 && newPlainTextValue.length <= 2000,
      },
      mentions: mentions,
    })
  }

  canReplyToComment(parentSlug) {
    var depthLevel = parentSlug.split('/').length - 1
    return depthLevel < 3
  }

  componentDidUpdate(prevProps) {
    if (this.state.showReply) {
      this.commentInput.focus()
    }
  }

  doHighlightMentions(text, mentions) {
    if (mentions.length === 0) {
      text = reactStringReplace(text, /(\r\n|\r|\n)/g, (match, i) => <br key={i} />)
      return text
    }

    for (var i = mentions.length - 1; i >= 0; i--) {
      var mention = mentions[i]
      text = reactStringReplace(text, mention.display, (match, i) => (
        <span key={match + i} style={{ color: '#22afe2', fontWeight: 'bold' }}>
          {match}
        </span>
      ))
    }
    text = reactStringReplace(text, /(\r\n|\r|\n)/g, (match, i) => <br key={i} />)
    return text
  }

  render() {
    const { comment, user, userId, postId } = this.props
    const nestedComments = (comment.children || []).map((comment) => {
      return (
        <Comment
          _creator={this.props._creator}
          handleMuteUser={this.props.handleMuteUser}
          handleDeleteComment={this.props.handleDeleteComment}
          handleIsLikeable={this.props.handleIsLikeable}
          handleAddComment={this.props.handleAddComment}
          comment={comment}
          user={user}
          members={this.props.members}
          key={comment._id}
          userId={userId}
          postId={postId}
          type={this.props.type}
        />
      )
    })

    const canDeleteComment = user.isAdmin || comment._creator._id === user._id
    const deleteCommentMarkup = canDeleteComment ? (
      <a
        href='#/'
        className='mr-3 text-danger'
        onClick={() => {
          this.handleDeleteCommentFromServer(comment)
        }}>
        Delete
      </a>
    ) : null
    const isAuthor = this.props._creator._id === comment._creator._id
    const authorMarkup = isAuthor ? (
      <div>
        <div className='badge badge-primary badge-pill mx-1 py-1'>Admin</div>
      </div>
    ) : null

    const memberName = comment._creator.firstName + ' ' + comment._creator.lastName
    const imageUrl = comment._creator.imageUrl
    const commentCreatedAt = `${Moment.utc(comment.createdAt).local().format('M/D/YY')} at ${Moment.utc(comment.createdAt).local().format('h:mm a')}`

    const commentLikeButton = (
      <>
        {this.state.likesCount > 0 && <span className='text-muted mr-1'>{this.state.likesCount}</span>}
        <PlainButton onClick={() => this.handleToggleLikeComment(comment)}>
          <i className={this.state.isLikeable ? 'fa fa-heart-o' : 'fa fa-heart'} style={{ color: '#e53737', fontSize: '0.94rem' }} />
        </PlainButton>
      </>
    )

    const commentContextMenu = (
      <div className='dropdown ml-3' style={{ lineHeight: '24px' }}>
        <div
          className='text-muted'
          style={{ padding: 0, fontSize: 16, border: 'none', lineHeight: '16px', cursor: 'pointer' }}
          id='dropdownMenuButton'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'>
          <i className='fa fa-ellipsis-v mr-1' />
        </div>
        <div className='dropdown-menu dropdown-menu-right fade' aria-labelledby='dropdownMenuButton'>
          <a className='dropdown-item' href='#/' onClick={() => this.handleReportComment(comment)}>
            <i className='fa fa-flag mr-2' />
            Report
          </a>
          <a className='dropdown-item' href='#/' onClick={() => this.handleMuteUser(comment)}>
            <i className='fa fa-ban mr-2' />
            Mute User
          </a>
        </div>
      </div>
    )

    const commentInputStyle = this.state.replyComment.valid ? defaultCommentInputSuccessStyle : defaultCommentInputStyle
    const commentStyle = merge({}, commentInputStyle, {
      input: {
        overflow: 'auto',
        maxHeight: 300,
      },
    })

    return (
      <div className='comment mb-2 row no-gutters'>
        <div className='comment-avatar col-auto mr-3 text-center'>
          <Link to={`/user/${comment._creator.username}`}>
            <UserAvatar size='32' className='align-middle' name={memberName} src={imageUrl} />
          </Link>
        </div>
        <div className='comment-content col-11'>
          <div className='row no-gutters d-flex align-items-center' style={{ fontSize: 11, lineHeight: '11px' }}>
            <Link className='text-dark font-weight-bold' to={`/user/${comment._creator.username}`}>
              {memberName}
            </Link>
            {authorMarkup}
            <span className='text-muted ml-2'>{commentCreatedAt}</span>
            <div className='flex-fill' />
            {deleteCommentMarkup}
            {commentLikeButton}
            {commentContextMenu}
          </div>
          <div className='row no-gutters my-1'>
            <div className='col'>
              <div style={{ fontSize: 14 }}>
                <p>
                  <Linkify>
                    <span className='span-word-break'>{this.doHighlightMentions(comment.text, comment.mentions)}</span>
                  </Linkify>
                  <br />
                  {comment.imageUrl ? <img className='img-fluid mt-2 rounded' style={{ maxHeight: 400 }} alt='attachment' src={comment.imageUrl} /> : null}
                  {this.canReplyToComment(comment.slug) && !this.state.showReply ? (
                    <a href='#/' className='d-block mt-2' style={{ fontSize: 'smaller' }} onClick={this.toggleReplyTextAreaVisible}>
                      Reply
                    </a>
                  ) : null}
                </p>
              </div>
              {this.state.showReply ? (
                <div className='mt-1 mb-3'>
                  <MentionsInput
                    id='replyComment'
                    style={commentStyle}
                    placeholder='Type your reply'
                    inputRef={(tag) => (this.commentInput = tag)}
                    value={this.state.replyComment.value}
                    onChange={this.handleCommentChange}>
                    <UserMention trigger='@' data={this.props.members} style={defaultMentionStyle} />
                  </MentionsInput>
                  <button className='btn btn-sm btn-secondary mt-1 pull-right' type='button' onClick={this.toggleReplyTextAreaVisible}>
                    Cancel
                  </button>
                  <button
                    className='btn btn-sm btn-primary mt-1 ml-2 pull-right'
                    type='button'
                    onClick={() => {
                      this.handleSubmitComment(comment._id, this.state.plainTextReplyComment)
                    }}
                    disabled={!this.state.plainTextReplyComment.valid}>
                    Send
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className='comment-reply col-11 offset-1'>{nestedComments}</div>
      </div>
    )
  }
}

const { shape, object, string, bool, number, func } = PropTypes

Comment.propTypes = {
  comment: object,
  userId: string,
  postId: string,
  type: string,
  handleAddComment: func,
  members: PropTypes.arrayOf(object),
  handleDeleteComment: func,
  handleIsLikeable: func,
  handleMuteUser: func,
  _creator: shape({
    _id: string,
    firstName: string,
    lastName: string,
    email: string,
    imageUrl: string,
  }),
  user: shape({
    firstName: string,
    lastName: string,
    email: string,
    address: string,
    phone: string,
    country: string,
    city: string,
    state: string,
    zip: string,
    imageUrl: string,
    _id: string,
    runningScore: number,
    isAdmin: bool,
    emailVerified: bool,
    phoneVerified: bool,
  }),
}

export default Comment
