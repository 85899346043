const constants = {
  magnify: {
    NAME: 'Magnify',
    HEADER_DISPLAY_NAME: 'magnify',
    LOGO_PATH: '/com-img/magnify/logo.svg',
    LOGO_WIDTH: '32',
    LOGO_HEIGHT: '32',
    IMG_PATH: '/com-img/magnify/',
    URL: 'https://www.magnifyyourvoice.com',
    IS_PRIMARY: true,
  },
  jbuzz: {
    NAME: 'JBuzzSTL',
    HEADER_DISPLAY_NAME: '',
    LOGO_PATH: '/com-img/jbuzz/logo-bw.png',
    LOGO_PATH_LIGHT: '/com-img/jbuzz/logo-blue.png',
    LOGO_WIDTH: '120',
    LOGO_HEIGHT: '33',
    IMG_PATH: '/com-img/jbuzz/',
    URL: 'https://www.jbuzzstl.com',
    IS_PRIMARY: false,
  },
  popscommunity: {
    NAME: 'POPS Community',
    HEADER_DISPLAY_NAME: '',
    LOGO_PATH: '/com-img/popscommunity/logo-bw.png',
    LOGO_PATH_LIGHT: '/com-img/popscommunity/logo-blue.png',
    LOGO_WIDTH: '82',
    LOGO_HEIGHT: '33',
    IMG_PATH: '/com-img/popscommunity/',
    URL: 'https://www.popscommunity.org',
    IS_PRIMARY: false,
  },
  fordschool: {
    NAME: 'Ford School Community',
    HEADER_DISPLAY_NAME: '',
    LOGO_PATH: '/com-img/fordschool/logo.svg',
    LOGO_PATH_LIGHT: '/com-img/fordschool/logo-inverse.svg',
    LOGO_WIDTH: '120',
    LOGO_HEIGHT: '19',
    IMG_PATH: '/com-img/fordschool/',
    URL: 'https://www.p3ecommunity.com',
    IS_PRIMARY: false,
  },
}

// default for dev
if (window.COMMUNITY === '$COMMUNITY') {
  window.COMMUNITY = 'jbuzz'
}

export default constants[window.COMMUNITY]
