import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'

import CategoryBox, { CompactCategoryBox } from './CategoryBox'

export function CategoriesList({ categories, selectedIDs = [], onSelect = () => {} }) {
  const [selectedIDsObj, setSelectedObj] = useState({})

  // Turn the selected ids into a dict
  useEffect(() => {
    const map = {}
    selectedIDs.forEach((id) => {
      map[id] = true
    })
    setSelectedObj(map)
  }, [selectedIDs])

  return (
    <div className='d-flex' style={{ flexWrap: 'wrap' }}>
      {categories.map((category) => (
        <div key={category.name} className='mr-2 mb-2'>
          <CategoryBox
            key={category.name}
            name={category.name}
            faImageName={category.fontawesomeImageName}
            onChange={(shouldSelect) => {
              onSelect(category._id, shouldSelect)
            }}
            isSelected={selectedIDsObj[category._id]}
          />
        </div>
      ))}
    </div>
  )
}

/**
 * A categories list without any side effects
 */
export function StaticCategoriesList({ categories, useCompactStyle }) {
  return (
    <div className='d-flex' style={{ flexWrap: 'wrap' }}>
      {categories.map((category) => (
        <div key={category.name} className='mr-2 mb-2'>
          <CategoryBox key={category.name} name={category.name} faImageName={category.fontawesomeImageName} compact={useCompactStyle} isSelected />
        </div>
      ))}
    </div>
  )
}

/**
 * A react-bootstrap Modal for picking categories.
 */
export function ChooseCategoriesModal({ initialPickedIDs, show, submitPicked, dismiss, title = 'Select Interests' }) {
  const [allCategories, setAllCategories] = useState([])
  const [pickedIDs, setPickedIDs] = useState(initialPickedIDs)

  // Keep the picked interests up to date with props
  useEffect(() => {
    setPickedIDs(initialPickedIDs)
  }, [initialPickedIDs])

  useEffect(() => {
    // Fetch the list of categories
    const fetchData = async () => {
      const catResp = await axios.get('/api/v2/categories')
      //console.log(catResp.data)
      if (catResp.data.categories) {
        setAllCategories(catResp.data.categories)
      } else {
        //console.log(catResp.data.message)
      }
    }
    fetchData()
  }, [])

  const selectCategoryID = (id, shouldSelect) => {
    if (shouldSelect) {
      const index = pickedIDs.indexOf(id)
      if (index === -1) {
        setPickedIDs(pickedIDs.concat([id]))
      }
    } else {
      setPickedIDs(pickedIDs.filter((item) => item !== id))
    }
  }

  const submit = () => {
    const map = {}
    pickedIDs.forEach((id) => {
      map[id] = true
    })

    submitPicked(allCategories.filter((category) => map[category._id]))
  }

  return (
    <Modal show={show} size='lg' onHide={dismiss}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CategoriesList categories={allCategories} selectedIDs={pickedIDs} onSelect={selectCategoryID} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={dismiss}>
          Cancel
        </Button>
        <Button variant='primary' onClick={submit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function InlineCategoryPicker({ submit }) {
  const [allCategories, setAllCategories] = useState([])
  const [pickedIDs, setPickedIDs] = useState([])

  useEffect(() => {
    // Fetch the list of categories
    const fetchData = async () => {
      const catResp = await axios.get('/api/v2/categories')
      //console.log(catResp.data)
      if (catResp.data.categories) {
        setAllCategories(catResp.data.categories)
      } else {
        //console.log(catResp.data.message)
      }
    }
    fetchData()
  }, [])

  const selectCategoryID = (id, shouldSelect) => {
    if (shouldSelect) {
      const index = pickedIDs.indexOf(id)
      if (index === -1) {
        setPickedIDs(pickedIDs.concat([id]))
      }
    } else {
      setPickedIDs(pickedIDs.filter((item) => item !== id))
    }
  }

  return (
    <div className='col bg-white p-4 rounded align-items-center'>
      <h4 className='mb-4'>Tell us what sorts of projects you like, so we can recommend new projects!</h4>
      <CategoriesList categories={allCategories} selectedIDs={pickedIDs} onSelect={selectCategoryID} />
      <div className='row no-gutters mt-4 justify-content-center'>
        <Button
          variant='primary'
          size='lg'
          onClick={() => {
            submit(pickedIDs)
          }}
          disabled={pickedIDs.length === 0}>
          Start showing me recommended projects!
        </Button>
      </div>
    </div>
  )
}
