import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import UserAvatar from 'react-user-avatar'
import Moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import { Img } from 'react-image'
import { toast } from 'react-toastify'
import Linkify from 'linkifyjs/react'

import api from '../model/ApiService'
import { isSuccess } from '../model/Util'
import * as lib from '../model/Utils'
import { RootState, TextPost } from '../model/types'
import CompactProjectCard from './CompactProjectCard'

export type TextPostContentProps = {
  post: TextPost
  isFirst: boolean | undefined
  didDeletePost: (id: string) => void
  didEditPost: (post: TextPost) => void
  showBackButton?: boolean
  showInModal?: () => void
}
const TextPostContent: React.FC<TextPostContentProps> = (props) => {
  const { post, isFirst, didDeletePost, didEditPost, showBackButton, showInModal } = props
  const user = useSelector((state: RootState) => state.user)

  const [likedByMe, setLikedByMe] = useState(false)
  const [likesCount, setLikesCount] = useState(0)

  const deletePost = useCallback(async () => {
    const resp = await api.deleteTextPost(post._id)
    //console.log(resp)
    if (isSuccess(resp.status)) {
      didDeletePost(post._id)
    }
  }, [post, didDeletePost])

  const reportPost = useCallback(async () => {
    const reportResp = await api.reportTextPost(post._id)
    if (isSuccess(reportResp.status)) {
      toast.success('Reported post.')
    } else {
      toast.warning('Error reporting post.')
    }
  }, [post])

  const fetchLikes = useCallback(async () => {
    return await api.getTextPostLikes(post._id)
  }, [post, user])

  useEffect(() => {
    let isMounted = true
    fetchLikes().then((likes) => {
      if (isMounted) {
        setLikedByMe(likes.data.data.some((like) => like._user == user._id))
        setLikesCount(likes.data.data.length)
      }
    })
    return () => {
      isMounted = false
    }
  }, [post, user, fetchLikes])

  const likePost = useCallback(async () => {
    const resp = await api.likeTextPost(post._id, !likedByMe)
    if (isSuccess(resp.status)) {
      setLikedByMe(!likedByMe)
      const likes = await fetchLikes()
      setLikedByMe(likes.data.data.some((like) => like._user == user._id))
      setLikesCount(likes.data.data.length)
    }
  }, [likedByMe, post, fetchLikes])

  const dateFormatted = lib.getFormattedDate(Moment.utc(post.createdAt).local())
  const showAdminActions = post._creator._id === user._id || user.isAdmin

  return (
    <div className={`row card rounded-lg ${isFirst ? '' : 'mt-3'}`} style={{ overflow: 'visible' }}>
      <div className='col'>
        <div className='row align-items-center pt-3 px-3 mb-3'>
          <Link to={`/user/${post._creator.username}`} className='d-flex'>
            <UserAvatar size='40px' name={post._creator.firstName + ' ' + post._creator.lastName} src={post._creator.imageUrl} className='mr-3' />
          </Link>
          <div className='ml-2'>
            <Link to={`/user/${post._creator.username}`} className='row font-weight-bold text-dark text-decoration-none'>
              {post._creator.firstName + ' ' + post._creator.lastName}
              {post.autoPostType && <span className='font-weight-light ml-1'>{post.text}</span>}
            </Link>
            <div className='row small' style={{ color: '#BBB' }}>
              {post._organization && (
                <Link to={`/organization/${post._organization.shortName}`} className='mr-3  text-decoration-none'>
                  <div className='row no-gutters align-items-center text-muted'>
                    <Img
                      src={[post._organization.imageUrl, '/com-img/magnify/logo-bw.svg']}
                      height='17'
                      width='17'
                      className='mr-2'
                      style={{ borderRadius: 4 }}
                    />
                    {post._organization.name}
                  </div>
                </Link>
              )}
              {dateFormatted}
            </div>
          </div>
          <div className='flex-fill' />
          <div className='dropdown mb-auto'>
            <div
              className='text-muted'
              style={{ padding: 0, fontSize: 16, border: 'none', lineHeight: '16px', cursor: 'pointer' }}
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'>
              <i className='fa fa-ellipsis-h mr-1' />
            </div>
            <div className='dropdown-menu fade dropdown-menu-right shadow-lg border-0 rounded-lg py-2 px-2 ' aria-labelledby='dropdownMenuButton'>
              {showAdminActions && (
                <>
                  <a
                    className='dropdown-item py-2 rounded'
                    href='#/'
                    onClick={() => {
                      didEditPost(post)
                    }}>
                    {' '}
                    <i className='fa fa-pencil mr-3' />
                    Edit
                  </a>
                  <a className='dropdown-item py-2 rounded' href='#/' onClick={deletePost}>
                    <i className='fa fa-trash-o mr-3' />
                    Delete
                  </a>
                </>
              )}
              <a className='dropdown-item py-2 rounded' href='#/' onClick={reportPost}>
                {' '}
                <i className='fa fa-flag mr-3' />
                Report
              </a>
            </div>
          </div>
        </div>

        {!post.autoPostType && post.text && (
          <div className='row mb-3 px-3'>
            <div className=''>
              <Linkify>{post.text}</Linkify>
            </div>
          </div>
        )}
        {post._post && (
          <div className='row mb-2 px-3'>
            <div className='mt-2 w-100'>
              <CompactProjectCard project={post._post} />
            </div>
          </div>
        )}
        {post.imageUrl && (
          <div className='row mb-2'>
            <Img
              src={[lib.getResizedWebImageUrl(post.imageUrl), post.imageUrl]}
              loader={<div className='bg-secondary' style={{ height: 400 }} />}
              width='100%'
              style={{ maxHeight: 400, objectFit: 'cover' }}
              key={lib.getResizedWebImageUrl(post.imageUrl)}
            />
          </div>
        )}
        {/* Likes and comment buttons */}
        <div className='row px-3 align-items-center mb-2'>
          <button className='btn btn-transparent text-primary btn-sm' onClick={likePost}>
            {likedByMe ? <i className='fa fa-solid fa-bullhorn mr-2 text-red' /> : <i className='fa fa-bullhorn mr-2' />}
            {likedByMe ? 'Cheered' : 'Cheer'}
          </button>
          {likesCount > 0 && (
            <div className='ml-1 text-muted' style={{ fontSize: 12 }}>
              {likesCount} cheer{likesCount == 1 ? '' : 's'}
            </div>
          )}
          {showInModal && (
            <button onClick={showInModal} className='btn btn-transparent ml-3 text-primary btn-sm align-items-center'>
              <i className='fa fa-comments-o mr-2' />
              {post.commentsCount > 1 ? `${post.commentsCount} Comments` : post.commentsCount === 1 ? `${post.commentsCount} Comment` : `Comments`}
            </button>
          )}
        </div>

        {props.children}
      </div>
    </div>
  )
}

export default TextPostContent
