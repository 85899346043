import React from 'react'
import { Link } from 'react-router-dom'
import constants from '../communities/constants'

class Footer extends React.Component {
  render() {
    let playStoreLink =
      'http://play.google.com/store/apps/details?id=com.magnifyyourvoice.magnify&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
    let appStoreLink = 'https://apps.apple.com/us/app/magnify-your-voice/id1466158622'

    if (constants.NAME === 'JBuzzSTL') {
      playStoreLink = 'https://play.google.com/store/apps/details?id=com.magnifyyourvoice.jbuzzstl'
      appStoreLink = 'https://apps.apple.com/us/app/jbuzz-stl/id1561222622?itsct=apps_box_link&itscg=30200'
    }

    return (
      <footer className='footer'>
        <div className='container text-center'>
          <ul className='social-links h5'>
            {constants.IS_PRIMARY && (
              <li>
                <a href='https://twitter.com/teammagnify' target='_blank' rel='noopener noreferrer'>
                  <i className='fa fa-twitter fa-lg fa-fw' style={{ color: '#FFFFFF' }} />
                </a>
              </li>
            )}
            {constants.IS_PRIMARY && (
              <li>
                <a href='https://www.facebook.com/teammagnify' target='_blank' rel='noopener noreferrer'>
                  <i className='fa fa-facebook fa-fw fa-lg' style={{ color: '#FFFFFF' }} />
                </a>
              </li>
            )}
            {constants.NAME === 'JBuzzSTL' && (
              <li>
                <a href='https://www.jfedstl.org/' target='_blank' rel='noopener noreferrer'>
                  <img src='/com-img/jbuzz/JFed-white.png' alt='JFed' style={{ height: 'auto', width: '160px', maxWidth: '160px' }} />
                </a>
              </li>
            )}
            <li>
              <a href={playStoreLink}>
                <img alt='Get it on Google Play' src='/img/GooglePlay.png' width='133' height='40' />
              </a>
            </li>
            <li>
              <a href={appStoreLink}>
                <img alt='Download on the App Store' src='/img/AppleStore-Dark.svg' width='120' height='40' />
              </a>
            </li>
          </ul>
          <div className='mt-1 h6'>
            {constants.NAME === 'Magnify' ? (
              <>
                <Link to='/about' style={{ color: '#FFFFFF' }}>
                  About Us
                </Link>
                <Link to='/help' className='ml-4' style={{ color: '#FFFFFF' }}>
                  Help
                </Link>
                <Link to='/feedback' className='ml-4' style={{ color: '#FFFFFF' }}>
                  Contact Us
                </Link>
                <a className='ml-4' style={{ color: '#FFFFFF' }} href='https://blog.magnifyyourvoice.com/blog' target='_blank' rel='noopener noreferrer'>
                  Blog
                </a>
              </>
            ) : (
              <span className='text-light'>
                {constants.NAME} is sponsored by the{' '}
                <a href='https://www.jfedstl.org' target='_blank' className='text-light font-weight-bold' style={{ color: '#FFFFFF' }}>
                  Jewish Federation of St. Louis.
                </a>
              </span>
            )}
          </div>
          <div className=''>
            <span className='text-light'>© 2023 Magnify Your Voice Inc.</span>
            <Link to='/tos' className='ml-4 font-weight-bold' style={{ color: '#FFFFFF' }}>
              Terms of Service
            </Link>
            {constants.NAME !== 'Magnify' && (
              <Link to='/feedback' className='ml-2 font-weight-bold' style={{ color: '#FFFFFF' }}>
                Contact Us
              </Link>
            )}
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
