import React from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { RootState } from '../../model/types'
import { Link } from 'react-router-dom'

const SurveyWelcomePost: React.FC = (props) => {
  const [cookies, setCookie] = useCookies(['surveyPostDismisedV2'])
  const user = useSelector((state: RootState) => state.user)

  const hidePost = () => {
    setCookie('surveyPostDismisedV2', true, { path: '/', expires: new Date(2147483647 * 1000) })
  }

  if (cookies.surveyPostDismisedV2 || user.completedSurvey) {
    return null
  }

  return (
    <div className='row card rounded-lg mt-3 p-3 d-flex flex-column align-items-start overflow-auto'>
      <div className='font-weight-bold d-flex flex-row w-100'>
        <div>Take a quick survey!</div>
        <div className='flex-fill' />
        <button type='button' className='close' data-dismiss='alert' aria-label='Close' onClick={hidePost}>
          <span aria-hidden='true'>&times;</span>
        </button>

        {/* <div className='flex-fill' />
        <button type='button' onClick={() => {}} className='closeButton card-shadow' aria-label='Cancel'>
          <span aria-hidden='true'>&times;</span>
        </button> */}
      </div>
      <div className='small' style={{ color: '#BBB' }}>
        From Magnify
      </div>
      <div className='mt-4 mb-3'>
        <span className='font-weight-bold'>{user.firstName}</span>, we want to show you the projects and events you care about the most. We&apos;ve created a
        quick, voluntary{' '}
        <Link to='/survey' className='font-weight-bold'>
          survey
        </Link>{' '}
        to improve your experience. You&apos;ll also earn{' '}
        <span className='badge badge-secondary badge-pill score-text mx-1'>
          <i className='fa fa-trophy fa-lg mt-1 mb-1' style={{ color: '#f2b424' }} /> + 10 points
        </span>{' '}
        on the leaderboard!
        {/* Earn
        <span className='badge badge-secondary badge-pill score-text ml-2'>
          <i className='fa fa-trophy fa-lg mt-1 mb-1' style={{ color: '#f2b424' }} /> + 10 points
        </span>{' '}
        on the{' '}
        <Link to='/leaderboard' className='text-dark'>
          Engagement Leaderboard
        </Link>{' '}
        by completing a{' '}
        <Link to='/survey' className='font-weight-bold'>
          Quick Survey
        </Link>
        . We use it to help show you the most relevant projects! */}
      </div>
      <a className='btn btn-primary mt-3' href='/survey'>
        Take the survey!
        <i className='fa fa-arrow-circle-right ml-2' />
      </a>
      <div className='pt-3 small mt-3'>
        You can always access the survey from the{' '}
        <Link to='/account' className='text-dark font-weight-bold'>
          Account
        </Link>{' '}
        page.
        <span className='text-muted font-weight-normal'> You can dismiss this message whenever you want.</span>
      </div>
    </div>
  )
}

export default SurveyWelcomePost
