import React, { useState } from 'react'
import AspectRatio from 'react-aspect-ratio'
import Dotdotdot from 'react-dotdotdot'
import { Project } from '../model/types'
import { Img } from 'react-image'
import FontAwesome from 'react-fontawesome'

import * as lib from '../model/Utils'

export type CompactProjectCardProps = {
  project: Project,
  secondaryCompact?: boolean,
  onClick?: () => void
}

const CompactProjectCard: React.FC<CompactProjectCardProps> = props => {
  const { project, secondaryCompact, onClick } = props

  const [isHovered, setIsHovered] = useState(false)

  // Create the src array
  const resizedImageUrl = project.imageUrl ? lib.getResizedUrl(project.imageUrl) : null
  let imageSrc = ['/img/loadingImage.svg']
  if (project.imageUrl) {
    imageSrc.unshift(project.imageUrl)
  }
  if (resizedImageUrl) {
    imageSrc.unshift(resizedImageUrl)
  }

  if (secondaryCompact) {
    return (
      <div
        id='postcard'
        className='rounded-lg w-100 d-flex bg-white card-shadow'
      >
        <a href={onClick ? '#' : `/${project.shortName}`} className='p-2 col' onClick={onClick}>
          <div className='row no-gutters align-items-center'>
            <Img
              src={imageSrc}
              loader={
                <Img src='/img/loadingImage.svg' />
              }
              width={40}
              height={40}
              style={{ objectFit: 'cover' }}
              className='rounded mr-3 bg-white'
            />
            <div className='col'>
              <div className='row no-gutters font-weight-bold small text-dark'>
                {project.title}
              </div>
            </div>
          </div>
        </a>
      </div>
    )
  }
  
  return (
    <div
      id='postcard'
      className='rounded-lg w-100 d-flex btn-light-secondary'
    >
      <a href={`/${project.shortName}`} className='p-3 col' >
        <div className='row no-gutters align-items-center'>
          <Img
            src={imageSrc}
            loader={
              <Img src='/img/loadingImage.svg' />
            }
            width={70}
            height={70}
            style={{ objectFit: 'cover' }}
            className='rounded mr-3 bg-white'
          />
          <div className='col'>
            <div className='row no-gutters font-weight-bold text-dark'>
              {project.title}
            </div>
            <div className='row no-gutters small text-muted' >
              <Dotdotdot clamp={2}>
                {project.text}
              </Dotdotdot>
            </div>
          </div>
        
        </div>
      </a>
    </div>
  )
}

export default CompactProjectCard