import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { setLoggedInUser } from './actionCreators'

/**
 * A hook to automatically reload the user on first load, and to dispatch it to the Redux store.
 */
export function useReloadUserOnce() {
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      const userResp = await axios.get('/api/v2/me')

      // dispatch change to redux
      if (userResp.data.success) {
        dispatch(setLoggedInUser(userResp.data.data))
      }
    }

    fetchData()
  }, [])
}

export default function useReloadUser() {
  const dispatch = useDispatch()

  return useCallback(() => {
    const fetchData = async () => {
      const userResp = await axios.get('/api/v2/me')

      // dispatch change to redux
      if (userResp.data.success) {
        dispatch(setLoggedInUser(userResp.data.data))
      }
    }
    fetchData()
  }, [dispatch])
}
