import React from 'react'
import PropTypes from 'prop-types'

function CategoryBox ({ name, faImageName, isSelected, onChange, compact }) {
  if (compact) {
    return <CompactCategoryBox name={name} faImageName={faImageName} isSelected={isSelected} onChange={onChange} />
  }

  return (
    <div onClick={() => { onChange?.(!isSelected) }}>
      <div className={`rounded-2 d-flex flex-column align-items-center card-shadow ${isSelected ? 'text-secondary bg-info-light' : 'text-secondary bg-light'}`} style={{ width: 100, height: 100 }}>
        <div className='d-flex justify-content-end px-1 pt-1 text-secondary' style={{ width: '100%' }}><i className='fa fa-check' style={{opacity: isSelected ? 1 : 0}}/></div>
        <i className={`fa fa-${faImageName}`} style={{ fontSize: 40 }} />
        <div className='my-2 px-2 d-flex flex-column justify-content-center' style={{ height: 28 }}>
          <div className='text-center' style={{ fontSize: 12, lineHeight: 1.2 }}>{name}</div>
        </div>
      </div>
    </div>
  )
}

export function CompactCategoryBox ({ name, faImageName, isSelected, onChange }) {
  return (
    <div onClick={() => { onChange?.(!isSelected) }}>
      <div className={`card-shadow rounded d-flex flex-row align-items-center p-2 ${isSelected ? 'text-secondary bg-info-light' : 'text-secondary bg-light'}`}>
        <i className={`fa fa-${faImageName} mr-2`} style={{ fontSize: 18 }} />
        <div className='text-center' style={{ fontSize: 15 }}>{name}</div>
      </div>
    </div>
  )
}

CategoryBox.propTypes = {
  name: PropTypes.string,
  faImageName: PropTypes.string,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func
}

export default CategoryBox
