import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import UserAvatar from 'react-user-avatar'
import { useSelector } from 'react-redux'
import { Modal, Spinner } from 'react-bootstrap'
import { Waypoint } from 'react-waypoint'
import { Link, useParams, useHistory } from 'react-router-dom'

import Header from '../components/Header'
import MainContainer from '../components/MainContainer'
import Footer from '../components/Footer'
import { OrganizationId, ProjectsState, RootState, TextPost } from '../model/types'
import { FeedState, getFeedAfterDeletedPost, getMorePosts, initialFeedState } from '../model/FeedManagement'
import FeedItemBox from '../components/FeedItemBox'
import TextPostsWelcomePost from '../components/AlertBoxes/TextPostsWelcomePost'
import SurveyWelcomePost from '../components/AlertBoxes/SurveyWelcomePost'
import CreateTextPostModal from '../components/CreateTextPostModal'
import ProjectFilters from '../model/ProjectFilters'
import { LoadProjectsResponse } from '../model/response-types'
import FollowersFollowingModal from '../components/FollowersFollowingModal'
import useReloadUser from '../redux/useReloadUser'
import TextPostModal from '../components/TextPostModal'

const FeedPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.user)
  const params = useParams() as any
  const history = useHistory()
  const textPostId = params.id
  const [orgs, setOrgs] = useState<OrganizationId[]>([])
  const [feed, setFeed] = useState<FeedState>(initialFeedState())
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [showNewPostDialog, setShowNewPostDialog] = useState<{ show: boolean; editingPost: TextPost | undefined }>({ show: false, editingPost: undefined })
  const [recommendedProjects, setRecommendedProjects] = useState<ProjectsState>({ projects: [], hasMore: true })
  const [nearbyProjects, setNearbyProjects] = useState<ProjectsState>({ projects: [], hasMore: true })
  const [showFollowersFollowingModal, setShowFollowersFollowingModal] = useState<null | 'followers' | 'following'>(null)
  const [showPost, setShowPost] = useState<{ show: boolean; post: TextPost | undefined }>({ show: textPostId ? true : false, post: undefined })

  useReloadUser()

  //console.log(`textPostId`, textPostId, showPost, params)

  useEffect(() => {
    setOrgs(user?.activeOrganizations?.map((orgWrapper) => orgWrapper._organization) ?? [])
  }, [user])

  // Callback to load more posts
  const fetchMore = useCallback(async () => {
    setIsLoadingMore(true)

    // Every three fetches, we need more recommended/nearby posts
    if (Math.floor(Math.floor(feed.offset / 9) / 3) == 0) {
      if (recommendedProjects.hasMore) {
        const moreRecommended = await await axios.get<LoadProjectsResponse>(
          `/api/v2/posts?skipItems=${recommendedProjects.projects.length}&filter=${ProjectFilters.FORYOU}&showCompleted=false`
        )
        if (moreRecommended.data.success) {
          if (moreRecommended.data.data && moreRecommended.data.data.length > 0) {
            setRecommendedProjects({ projects: recommendedProjects.projects.concat(moreRecommended.data.data), hasMore: true })
          } else {
            setRecommendedProjects({ ...recommendedProjects, hasMore: false })
          }
        }
      }

      if (nearbyProjects.hasMore) {
        const moreNearby = await await axios.get<LoadProjectsResponse>(
          `/api/v2/posts?skipItems=${nearbyProjects.projects.length}&filter=${ProjectFilters.NEARBY}&showCompleted=false`
        )
        if (moreNearby.data.success) {
          if (moreNearby.data.data && moreNearby.data.data.length > 0) {
            setNearbyProjects({ projects: nearbyProjects.projects.concat(moreNearby.data.data), hasMore: true })
          } else {
            setNearbyProjects({ ...nearbyProjects, hasMore: false })
          }
        }
      }
    }

    const newFeed = await getMorePosts(feed)
    //console.log(newFeed)
    setFeed(newFeed)
    setIsLoadingMore(false)
  }, [feed])

  // Do an initial load
  useEffect(() => {
    fetchMore()
  }, [])

  const didPostNewPost = useCallback(async () => {
    setShowNewPostDialog({ show: false, editingPost: undefined })
    // Reload the feed
    setFeed(await getMorePosts())
  }, [])

  const didDeletePost = useCallback(
    async (id: string) => {
      setShowPost({ show: false, post: showPost.post })
      setFeed(getFeedAfterDeletedPost(feed, id))
    },
    [feed]
  )

  const pendingNumber = user.followers?.find((entry) => entry._id == false)?.count ?? 0

  return (
    <div>
      <Header />
      <MainContainer noTopMargin>
        <div className='col-12 mx-auto'>
          <div className='row'>
            {/* First Column */}
            <div
              className='d-none d-md-block col-4 pt-4 pr-5 position-sticky position-top-with-padding overflow-auto hide-scrollbar'
              style={{ height: 'calc(100vh - 61px)' }}>
              <h2 className='font-weight-bold mb-2'>Home</h2>
              {/* <hr /> */}
              <a className='row platter-link' href={`/user/${user.username}`}>
                <div className='d-flex flex-row align-items-center w-100 py-2 px-3 rounded-lg'>
                  <UserAvatar size='40px' name={user.firstName + ' ' + user.lastName} src={user.imageUrl} className='mr-3' />
                  <div className='text-dark'>{user.firstName + ' ' + user.lastName}</div>
                </div>
              </a>
              <a
                className='row platter-link text-dark'
                href='#'
                onClick={() => {
                  setShowFollowersFollowingModal('followers')
                }}>
                <div className='d-flex flex-row align-items-center w-100 py-2 px-3 rounded-lg'>
                  <i
                    className='fa fa-address-book-o text-primary mr-3 bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                    style={{ width: 40, height: 40 }}
                  />
                  <div className='d-flex flex-column'>
                    <div>My Followers</div>
                    {pendingNumber > 0 && (
                      <div className='small text-info'>
                        <i className='fa fa-circle mr-1' />
                        {pendingNumber} Pending
                      </div>
                    )}
                  </div>
                </div>
              </a>
              <a
                className='row platter-link text-dark'
                href='#'
                onClick={() => {
                  setShowFollowersFollowingModal('following')
                }}>
                <div className='d-flex flex-row align-items-center w-100 py-2 px-3 rounded-lg'>
                  <i
                    className='fa-solid fa-user-check text-info mr-3 bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                    style={{ width: 40, height: 40 }}
                  />
                  <div>Following Users</div>
                </div>
              </a>
              <a className='row platter-link text-dark' href='/following'>
                <div className='d-flex flex-row align-items-center w-100 py-2 px-3 rounded-lg'>
                  <i
                    className='fa-regular fa-calendar-check text-success mr-3 bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                    style={{ width: 40, height: 40 }}
                  />
                  <div>Following Projects</div>
                </div>
              </a>

              {/* <a className='row platter-link text-dark' href='/discover'>
                <div className='d-flex flex-row align-items-center w-100 py-2 px-3 rounded-lg'>
                  <i
                    className='fa fa-binoculars text-success mr-3 bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                    style={{ width: 40, height: 40 }}
                  />
                  <div>Discover</div>
                </div>
              </a>
              <a className='row platter-link text-dark' href='/calendar'>
                <div className='d-flex flex-row align-items-center w-100 py-2 px-3 rounded-lg'>
                  <i
                    className='fa fa-calendar-o text-info mr-3 bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                    style={{ width: 40, height: 40 }}
                  />
                  <div>Calendar</div>
                </div>
              </a> */}
              <hr className='my-4' />
              <div className='text-muted pb-2 font-weight-bold'>My Organizations</div>
              {orgs.map((org) => (
                <Link to={`/organization/${org.shortName}`} className='row platter-link' key={org._id}>
                  <div className='d-flex flex-row align-items-center w-100 py-2 px-3 rounded-lg'>
                    <img
                      src={org.imageUrl ? org.imageUrl : '/com-img/magnify/logo-bw.svg'}
                      alt='Organization Logo'
                      width='40'
                      height='40'
                      style={{ objectFit: 'cover' }}
                      className='rounded mr-3'
                    />
                    <div className='text-dark'>{org.name}</div>
                  </div>
                </Link>
              ))}
              <a className='row platter-link text-dark' href='/organizations'>
                <div className='d-flex flex-row align-items-center w-100 py-2 px-3 rounded-lg'>
                  <i
                    className='fa fa-th-large mr-3 bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                    style={{ width: 40, height: 40 }}
                  />
                  <div>View all</div>
                </div>
              </a>
              {/* <hr className='my-4' />
              <div className='text-muted font-weight-bold'>Upcoming Events</div> */}
            </div>
            {/* Second Column */}
            <div className='col-12 col-md-8 pt-4'>
              {/* On mobile, we hide the left sidebar. Instead, show quick links here. */}
              <div className='d-block d-md-none row d-flex flex-row justify-content-center mb-3'>
                <a className='text-dark flex-fill' href={`/user/${user.username}`}>
                  <div className='d-flex flex-column align-items-center'>
                    <UserAvatar size='50px' name={user.firstName + ' ' + user.lastName} src={user.imageUrl} />
                    <div className='text-center small mt-2'>{user.firstName + ' ' + user.lastName}</div>
                  </div>
                </a>
                <a className='text-dark ml-4 flex-fill' href='/following'>
                  <div className='d-flex flex-column align-items-center'>
                    <i
                      className='fa fa-heart-o text-danger bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                      style={{ width: 50, height: 50 }}
                    />
                    <div className='text-center small mt-2'>Following</div>
                  </div>
                </a>
                <a className='text-dark ml-4 flex-fill' href='/organizations'>
                  <div className='d-flex flex-column align-items-center'>
                    <i
                      className='fa fa-users text-primary bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                      style={{ width: 50, height: 50 }}
                    />
                    <div className='text-center small mt-2'>My Orgs</div>
                  </div>
                </a>
                <a className='text-dark ml-4 flex-fill' href='/discover'>
                  <div className='d-flex flex-column align-items-center'>
                    <i
                      className='fa fa-binoculars text-success bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                      style={{ width: 50, height: 50 }}
                    />
                    <div className='text-center small mt-2'>Discover</div>
                  </div>
                </a>
              </div>
              <div className='row d-flex flex-row'>
                <div className='col-12 card rounded-lg p-3'>
                  <div className='d-flex flex-row'>
                    <UserAvatar size='3.0rem' className='align-middle my-auto mr-3' name={user.firstName + ' ' + user.lastName} src={user.imageUrl} />
                    <button
                      onClick={() => {
                        setShowNewPostDialog({ show: true, editingPost: undefined })
                      }}
                      className='btn btn-light-secondary flex-fill text-muted d-flex flex-row align-items-center'
                      style={{ boxShadow: 'none' }}>
                      How are you making a difference today, {user.firstName}?
                    </button>
                  </div>
                </div>
              </div>
              <TextPostsWelcomePost />
              <SurveyWelcomePost />
              {feed.currentItems.map((feedItem, i) => (
                <FeedItemBox
                  item={feedItem}
                  recommendedProjects={recommendedProjects.projects}
                  nearbyProjects={nearbyProjects.projects}
                  didDeletePost={didDeletePost}
                  didEditPost={(post) => {
                    setShowNewPostDialog({ show: true, editingPost: post })
                  }}
                  showInModal={(post) => setShowPost({ show: true, post })}
                  key={i}
                />
              ))}
              {!isLoadingMore && feed.hasMore && <Waypoint onEnter={fetchMore} />}
              {isLoadingMore && (
                <div className='row flex-fill justify-content-center mt-4'>
                  <Spinner animation='border' variant='primary' role='status' />
                </div>
              )}
            </div>
          </div>
        </div>
      </MainContainer>
      <Footer />

      <CreateTextPostModal
        dismiss={() => {
          setShowNewPostDialog({ show: false, editingPost: undefined })
        }}
        save={didPostNewPost}
        show={showNewPostDialog.show}
        editingPost={showNewPostDialog.editingPost}
      />

      <FollowersFollowingModal
        show={showFollowersFollowingModal == 'followers'}
        dismiss={() => {
          setShowFollowersFollowingModal(null)
        }}
        type={'followers'}
      />
      <FollowersFollowingModal
        show={showFollowersFollowingModal == 'following'}
        dismiss={() => {
          setShowFollowersFollowingModal(null)
        }}
        type={'following'}
      />
      <TextPostModal
        show={showPost.show}
        dismiss={() => {
          history.push('/home')
          setShowPost({ show: false, post: showPost.post })
        }}
        didDeletePost={didDeletePost}
        didEditPost={(post) => {
          setShowPost({ show: false, post: showPost.post })
          setShowNewPostDialog({ show: true, editingPost: post })
        }}
        post={showPost.post}
        postId={textPostId}
      />
    </div>
  )
}

export default FeedPage
