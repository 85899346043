import React from 'react'

function PlainButton ({ onClick, children }) {
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }} role='button'>
      {children}
    </div>
  )
}

export default PlainButton
