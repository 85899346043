import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../model/ApiService'
import { Organization } from '../model/types'
import SmartOrgCard from './SmartOrgCard'

export type OrganizationsListProps = {
}


const OrganizationsList: React.FC<OrganizationsListProps> = props => {
  const [allOrgs, setAllOrgs] = useState<Organization[]>([])

  const history = useHistory()
  /**
   * Fetch the orgs to show
   */
  useEffect(() => {
    const fetchData = async () => {
      const orgsResp = await api.getAllOrganizations()
      if (orgsResp.data.success) {
        setAllOrgs(orgsResp.data.data)
        //console.log(orgsResp)
      }
    }
    fetchData()
  }, [])

  const joinOrganization = useCallback(async (id: string, shortname: string) => {
    const joinResp = await api.joinOrganization(id)
    if (joinResp.data.success) {
      history.push(`/organization/${shortname}`)
    }
  }, [])

  return (
    <div className='row'>
      {
        allOrgs.filter(org => !org.isMember).map(org => (
          <SmartOrgCard org={org} handleJoinOrganization={() => { joinOrganization(org._id, org.shortName) }} handleLeaveOrganization={() => { }}/>
        ))
      }
    </div>
  )
}

export default OrganizationsList
