import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import UserAvatar from 'react-user-avatar'
import Comment from './Comment'
import { connect } from 'react-redux'
import { MentionsInput, Mention as UserMention } from 'react-mentions'
import { merge } from 'lodash'
import { Spinner } from 'react-bootstrap'

import defaultCommentInputStyle from '../../styles/defaultCommentInputStyle'
import defaultCommentInputSuccessStyle from '../../styles/defaultCommentInputSuccessStyle'
import defaultMentionStyle from '../../styles/defaultMentionStyle'

class CommentControl extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      comments: [],
      commentsWithChildren: [],
      comment: {
        value: '',
        valid: false,
      },
      plainTextComment: {
        value: '',
        valid: false,
      },
      mentions: [],
      likes: {},
      commentsCount: 0,
      isLoading: true,
      isPosting: false,
      hasMore: false,
      uploadProgress: '',
      file: null,
    }
    this.handleCommentChange = this.handleCommentChange.bind(this)
    this.handleSubmitComment = this.handleSubmitComment.bind(this)
    this.handleAddComment = this.handleAddComment.bind(this)
    this.handleDeleteComment = this.handleDeleteComment.bind(this)
    this.handleLoadPreviousComments = this.handleLoadPreviousComments.bind(this)
    this.handleIsLikeable = this.handleIsLikeable.bind(this)
    this.handleReloadCommentThread = this.handleReloadCommentThread.bind(this)
    this.initializeControl = this.initializeControl.bind(this)
    this.handleFileInputChanged = this.handleFileInputChanged.bind(this)
    this.handleUploadImage = this.handleUploadImage.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
    this.handleCancelImageInput = this.handleCancelImageInput.bind(this)
    this.canPost = this.canPost.bind(this)
  }

  handleUploadImage() {
    if (this.state.file) {
      this.setState({ isPosting: true })
      axios
        .get(`/api/v2/webimage-s3?fileName=${this.state.file.name}&fileType=${this.state.file.type}`)
        .then((response) => {
          const uploadRequest = response.data
          this.handleUpload(this.state.file, uploadRequest.signedRequest, uploadRequest.url)
        })
        .catch((error) => {
          console.error('axios error', error)
          this.setState({ isPosting: false })
        })
    }
  }

  handleUpload(file, signedRequest, url) {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.setState({
          uploadProgress: 'Sending ' + percentCompleted + '%',
        })
      }.bind(this),
    }

    axios
      .put(signedRequest, file, options)
      .then(
        function (res) {
          this.setState({ uploadProgress: 'Optimizing ...' })
          setTimeout(
            function () {
              // console.log(res)
              this.setState({
                uploadProgress: '',
                file: null,
              })
              this.handleSubmitComment(url)
            }.bind(this),
            5000
          )
        }.bind(this)
      )
      .catch(
        function (err) {
          this.setState({ uploadProgress: err.message, isPosting: false })
          console.log(err)
        }.bind(this)
      )
  }

  handleFileInputChanged(e) {
    e.preventDefault()
    const file = e.target.files[0]
    if (file) {
      this.setState({
        file,
      })
    }
  }

  handleCancelImageInput() {
    this.setState({
      file: null,
    })
  }

  handleIsLikeable(commentId) {
    return !this.state.likes[commentId]
  }

  handleLoadPreviousComments() {
    this.setState({ isLoading: true })

    axios
      .get(`/api/v2/comment/${this.props.type}/${this.props.postId}?skipItems=${this.state.commentsCount}`)
      .then((response) => {
        let currentComments = this.state.comments
        let hasMore
        if (response.data.success) {
          if (response.data.data.length > 0) {
            hasMore = response.data.data.length === 10
            const comments = response.data.data
            comments.sort(this.sortComments)
            currentComments = comments.concat(currentComments)

            this.setState({
              comments: currentComments,
              commentsWithChildren: this.nestComments(currentComments),
              commentsCount: currentComments.length,
            })
          }
        }

        this.setState({
          isLoading: false,
          hasMore,
        })
      })
      .catch((error) => {
        console.error('axios error', error)
        this.setState({ isLoading: false, hasMore: false })
      })
  }

  nestComments(commentList) {
    const commentMap = {}

    // move all the comments into a map of id => comment
    commentList.forEach((comment) => {
      commentMap[comment._id] = comment
      comment.children = []
    })

    // iterate over the comments again and correctly nest the children
    commentList.forEach((comment) => {
      if (comment._parentId) {
        const parent = commentMap[comment._parentId]
        // Be sure the parent comment is here
        if (parent) {
          if (!parent.children) {
            parent.children = []
          }
          parent.children.push(comment)
        }
      }
    })

    // filter the list to return a list of correctly nested comments
    return commentList.filter((comment) => {
      return !comment._parentId
    })
  }

  sortComments(a, b) {
    if (a.fullSlug < b.fullSlug) {
      return -1
    }
    if (a.fullSlug > b.fullSlug) {
      return 1
    }
    return 0
  }

  handleAddComment(comment) {
    const updatedComments = [comment].concat(this.state.comments)
    updatedComments.sort(this.sortComments)
    this.setState({
      comments: updatedComments,
      commentsWithChildren: this.nestComments(updatedComments),
      commentsCount: updatedComments.length,
    })
  }

  handleDeleteComment(comment) {
    const updatedComments = this.state.comments.filter((e) => e.fullSlug.indexOf(comment.fullSlug) === -1)
    updatedComments.sort(this.sortComments)
    this.setState({
      comments: updatedComments,
      commentsWithChildren: this.nestComments(updatedComments),
      commentsCount: updatedComments.length,
    })
  }

  handleReloadCommentThread() {
    const newState = {
      comments: [],
      commentsWithChildren: [],
      comment: {
        value: '',
        valid: false,
      },
      plainTextComment: {
        value: '',
        valid: false,
      },
      mentions: [],
      likes: {},
      commentsCount: 0,
      isLoading: true,
      hasMore: false,
    }
    this.setState(newState, () => this.initializeControl())
  }

  handleCommentChange(e, newValue, newPlainTextValue, mentions) {
    this.setState({
      comment: {
        value: newValue,
        valid: newValue.length > 0 && newValue.length <= 2000,
      },
      plainTextComment: {
        value: newPlainTextValue,
        valid: newPlainTextValue.length > 0 && newPlainTextValue.length <= 2000,
      },
      mentions,
    })
  }

  handleSubmitComment(liveImageUrl) {
    if (!this.state.plainTextComment.valid && !liveImageUrl) {
      return
    }

    const data = {
      userId: this.props.userId,
      text: this.state.plainTextComment.value.trim(),
      mentions: this.state.mentions,
      imageUrl: liveImageUrl,
    }

    if (this.props.type === 'post') {
      data['postId'] = this.props.postId
    } else if (this.props.type === 'textpost') {
      data['textPostId'] = this.props.postId
    } else {
      return
    }

    axios
      .post(`/api/v2/comment/${this.props.type}`, data)
      .then(
        function (response) {
          // console.log(response.data)
          this.handleAddComment(response.data.data)
          this.setState({
            comment: {
              value: '',
              valid: false,
            },
            isPosting: false,
          })
        }.bind(this)
      )
      .catch(function (error) {
        console.log(error)
        this.setState({ isPosting: false })
      })
  }

  initializeControl() {
    axios
      .get(`/api/v2/comment/likes/${this.props.type}/${this.props.postId}`)
      .then((response) => {
        if (response.data.success) {
          const likes = response.data.data

          // Make a hashmap and store it in the state
          const result = likes.reduce(function (map, like) {
            map[like._comment._id] = like._comment
            return map
          }, {})

          this.setState(
            {
              likes: result,
            },
            () => {
              axios
                .get(`/api/v2/comment/${this.props.type}/${this.props.postId}?skipItems=${this.state.commentsCount}`)
                .then((response) => {
                  if (response.data.success) {
                    const comments = response.data.data
                    comments.sort(this.sortComments)
                    this.setState({
                      comments,
                      commentsCount: comments.length,
                      isLoading: false,
                      hasMore: comments.length === 10,
                      commentsWithChildren: this.nestComments(comments),
                    })
                  }
                })
                .catch((error) => {
                  console.error('axios error', error)
                  this.setState({ isLoading: false, hasMore: false })
                })
            }
          )
        }
      })
      .catch((error) => {
        console.error('axios error', error)
        this.setState({ isLoading: false, hasMore: false })
      })
  }

  canPost() {
    return (this.state.comment.valid || this.state.file) && !this.state.isPosting
  }

  componentDidMount() {
    this.initializeControl()
  }

  render() {
    const adminFullName = `${this.props._creator.firstName} ${this.props._creator.lastName}`

    const previewImg = this.state.file ? URL.createObjectURL(this.state.file) : null

    const groupMembers = this.props.members.map((member) => {
      return { id: member._user.username, display: member._user.firstName + ' ' + member._user.lastName }
    })
    // groupMembers.unshift({ id: this.props._creator.username, display: adminFullName })

    const commentInputStyle = this.state.comment.valid ? defaultCommentInputSuccessStyle : defaultCommentInputStyle
    const commentStyle = merge({}, commentInputStyle, {
      input: {
        overflow: 'auto',
        maxHeight: 300,
      },
    })

    let commentsMarkup = this.state.commentsWithChildren.map((comment) => {
      return (
        <Comment
          _creator={this.props._creator}
          handleMuteUser={this.handleReloadCommentThread}
          handleDeleteComment={this.handleDeleteComment}
          handleIsLikeable={this.handleIsLikeable}
          handleAddComment={this.handleAddComment}
          comment={comment}
          user={this.props.user}
          key={comment._id}
          userId={this.props.userId}
          members={groupMembers}
          postId={this.props.postId}
          type={this.props.type}
        />
      )
    })

    if (this.state.commentsWithChildren.length == 0) {
      if (this.state.isLoading) {
        commentsMarkup = (
          <div className='row flex-fill justify-content-center mt-4 mb-5'>
            <Spinner animation='border' variant='primary' role='status' />
          </div>
        )
      } else {
        commentsMarkup = <div className='text-faint small py-5 mb-3 text-center'>No comments yet</div>
      }
    }

    const userFullName = this.props.user.firstName + ' ' + this.props.user.lastName

    return (
      <div className='card rounded mt-3 mb-4 pt-3 px-3 d-flex flex-column' style={{ overflow: 'visible' }}>
        <div className='row'>
          <div className='col'>
            <h5 className='font-weight-semibold'>
              Comments{' '}
              {this.state.hasMore ? (
                <small className='pull-right'>
                  <button className='btn btn-link btn-sm' type='button' onClick={this.handleLoadPreviousComments}>
                    Show Previous Comments
                  </button>
                </small>
              ) : null}
            </h5>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col'>{commentsMarkup}</div>
        </div>
        <div className='flex-grow-1' />
        <div className='row py-2 border-top'>
          <div className='col'>
            <div className='row no-gutters'>
              <div className='comment-avatar col-auto text-center mr-2'>
                <UserAvatar size='32' className='align-middle' name={userFullName} src={this.props.user.imageUrl} />
              </div>
              {!this.state.isPosting && (
                <div className='comment-content col'>
                  <MentionsInput
                    id='comment'
                    style={commentStyle}
                    placeholder='Leave a comment, or mention by typing @...'
                    inputRef={(tag) => (this.commentInput = tag)}
                    value={this.state.comment.value}
                    onChange={this.handleCommentChange}>
                    <UserMention trigger='@' data={groupMembers} style={defaultMentionStyle} />
                  </MentionsInput>

                  {!this.state.isPosting && (
                    <div className='row no-gutters'>
                      <div className='col'>
                        {this.state.file && (
                          <div className='mb-2 mt-2'>
                            <div className='commentImageUploadContainer'>
                              <img src={previewImg} alt='preview' className='object-fit-cover rounded-lg p-2' width={100} height={100} />
                              <button type='button' className='closeButton card-shadow' onClick={this.handleCancelImageInput} aria-label='Cancel'>
                                <span aria-hidden='true'>&times;</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {this.state.isPosting && (
                <div className='col d-flex justify-content-center align-items-center w-100' style={{ height: 200 }}>
                  <div className='spinner-grow text-info' style={{ width: '3rem', height: '3rem' }} role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                </div>
              )}
              <div className='col-auto'>
                <div className='row no-gutters align-items-center'>
                  <label className='cursor-pointer m-0'>
                    <input
                      className='d-none'
                      type='file'
                      accept='image/*'
                      onChange={this.handleFileInputChanged}
                      disabled={this.state.isPosting}
                      onClick={(event) => {
                        event.target.value = null
                      }}
                    />
                    <i className='fa fa-image text-secondary' />
                  </label>
                  <span className='ml-2 mr-2 text-secondary'>{this.state.uploadProgress}</span>
                  <button
                    className='btn btn-primary btn-sm pull-right'
                    type='button'
                    onClick={() => (this.state.file ? this.handleUploadImage() : this.handleSubmitComment(null))}
                    disabled={!this.canPost()}>
                    Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const { shape, string, bool, number, object } = PropTypes

CommentControl.propTypes = {
  userId: string,
  postId: string,
  type: string,
  members: PropTypes.arrayOf(object),
  _creator: shape({
    _id: string,
    firstName: string,
    lastName: string,
    email: string,
    imageUrl: string,
    username: string,
  }),
  user: shape({
    firstName: string,
    lastName: string,
    email: string,
    address: string,
    phone: string,
    country: string,
    city: string,
    state: string,
    zip: string,
    imageUrl: string,
    _id: string,
    runningScore: number,
    isAdmin: bool,
    emailVerified: bool,
    phoneVerified: bool,
  }),
}

const mapStateToProps = (state) => ({ user: state.user })
export default connect(mapStateToProps)(CommentControl)
