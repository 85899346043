export function isSuccess(requestCode: number): boolean {
  return requestCode == 200 || requestCode == 304
}

export function hashString(s: string): number {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
}

export function mod(n: number, m: number): number {
  return ((n % m) + m) % m
}
