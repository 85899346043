import { func } from 'prop-types'

const ProjectFilters = {
  NEARBY: 'nearMe',
  RECENT: 'recentlyUpdated',
  EVENTS: 'events',
  NATIONAL: 'national',
  FORYOU: 'recommended'
}

export function displayForFilter (filter) {
  switch (filter) {
    case ProjectFilters.NEARBY:
      return { title: 'Nearby Projects', description: 'Based on your ZIP code' }
    case ProjectFilters.RECENT:
      return { title: 'All Recently Updated', description: 'All projects sorted by most recent' }
    case ProjectFilters.EVENTS:
      return { title: 'Events', description: 'Projects on a certain date' }
    case ProjectFilters.NATIONAL:
      return { title: 'National Projects', description: 'Projects you can complete from anywhere' }
    case ProjectFilters.FORYOU:
      return { title: 'Recommended for you', description: 'Based on interests and organizations you follow' }
    default:
      return { title: 'Error', description: 'Not a valid filter' }
  }
}

export default ProjectFilters
