import React from 'react'
import { Helmet } from 'react-helmet'

import constants from '../communities/constants'

// Applies the right meta for the site, depending on window.COMMUNITY
function CommunityMeta() {
  //console.log(constants.IMG_PATH + 'logo-32.png')

  return (
    <Helmet>
      <title>{constants.NAME}</title>

      <link rel='icon' type='image/png' href={constants.IMG_PATH + 'logo-96.png'} sizes='96x96' />
      <link rel='icon' type='image/png' href={constants.IMG_PATH + 'logo-16.png'} sizes='16x16' />
      <link rel='icon' type='image/png' href={constants.IMG_PATH + 'logo-32.png'} sizes='32x32' />
    </Helmet>
  )
}

export default CommunityMeta
