import React from 'react'
import { connect } from 'react-redux'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'

function InterestsBanner({ user }) {
  const [cookies, setCookie] = useCookies(['interestBannerDismissed'])

  const hideBanner = () => {
    setCookie('interestBannerDismissed', true, { path: '/', expires: new Date(2147483647 * 1000) })
  }

  if (cookies.interestBannerDismissed || user.interests) {
    return null
  }

  return (
    <div className='row'>
      <div className='col-12 mb-2'>
        <div className='alert alert-primary mb-2 alert-dismissible fade show' role='alert'>
          <span>
            Please tell us what kinds of projects you like! Choose your interests on your{' '}
            <Link className='alert-link' to='/profile'>
              Profile
            </Link>{' '}
            page.
          </span>
          <span className='pull-right'>
            <button type='button' className='close' data-dismiss='alert' aria-label='Close' onClick={hideBanner}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.user })

export default connect(mapStateToProps)(InterestsBanner)
