// Contains API calls used by the client
// Prefer adding calls here to hard-coding them
import axios from 'axios'
import { NewTextPost } from './types'
import {
  EmptyResponse,
  LoadFollowersResponse,
  LoadFollowingResponse,
  LoadOrganizationsResponse,
  LoadProjectsResponse,
  LoadTextPostLikesResponse,
  LoadTextPostsResponse,
  LoadUserProfileResponse,
  LoadUsersResponse,
  NewTextPostResponse,
  SuccessErrorResponse,
} from './response-types'

// Rather than throwing other codes as errors, handle them manually.
axios.defaults.validateStatus = function () {
  return true
}
class ApiService {
  // User
  async getUser(username: string) {
    return await axios.get<LoadUserProfileResponse>(`/api/v2/u/${username}`)
  }

  async getOrganizationFollowers(organizationId: string, skipItems?: number) {
    return await axios.get(`/api/v2/organization/members/${organizationId}?skipItems=${skipItems}`)
  }

  async getUserProjects(username: string, offset = 0) {
    return await axios.get<LoadProjectsResponse>(`/api/v2/u/${username}/posts?skipItems=${offset}`)
  }

  async getUserFollowedProjects(username: string, offset = 0) {
    return await axios.get<LoadProjectsResponse>(`/api/v2/u/${username}/joined?skipItems=${offset}`)
  }

  async getFollowers(type: 'pending' | 'approved', offset = 0) {
    return await axios.get<LoadFollowersResponse>(`/api/v2/f/followers?isApproved=${type === 'approved'}&skipItems=${offset}`)
  }

  async getFollowing(type: 'pending' | 'approved', offset = 0) {
    return await axios.get<LoadFollowingResponse>(`/api/v2/f/following?isApproved=${type === 'approved'}&skipItems=${offset}`)
  }

  async followUser(id: string) {
    return await axios.post<EmptyResponse>(`/api/v2/f/follow/${id}`)
  }

  async unfollowUser(id: string) {
    return await axios.post<EmptyResponse>(`/api/v2/f/unfollow/${id}`)
  }

  async setFollowRequestStatus(id: string, isApproved: boolean) {
    return await axios.post<EmptyResponse>(`/api/v2/f/update`, { followId: id, isApproved })
  }

  async searchUsers(searchTerm: string, offset = 0) {
    return await axios.get<LoadUsersResponse>(`/api/v2/u/search?skipItems=${offset}&query=${searchTerm}`)
  }

  // Projects
  async searchProjects(searchTerm: string, offset = 0, showCompleted = false) {
    return await axios.get<LoadProjectsResponse>(`/api/v2/posts?skipItems=${offset}&searchTerm=${searchTerm}&showCompleted=${showCompleted}`)
  }

  // Organizations
  /**
   *
   * @returns A list of all organizations on the site
   */
  async getAllOrganizations() {
    return await axios.get<LoadOrganizationsResponse>('/api/v2/organizations')
  }

  async searchOrganizations(searchTerm: string, offset = 0) {
    return await axios.get(`/api/v2/o/search?skipItems=${offset}&query=${searchTerm}`)
  }

  /**
   * Tries to join an organization with a given id.
   * @param orgId
   * @returns
   */
  async joinOrganization(orgId: string) {
    const body = { orgId }
    return await axios.post<SuccessErrorResponse>('/api/v2/organization/joinpublic', body)
  }

  // Text posts
  async getUserPosts(username: string, offset = 0) {
    return await axios.get<LoadTextPostsResponse>(`/api/v2/u/${username}/textposts?skipItems=${offset}`)
  }

  async getOrganizationPosts(id: string, offset = 0) {
    return await axios.get<LoadTextPostsResponse>(`/api/v2/o/${id}/textposts?skipItems=${offset}`)
  }

  async createTextPost(newTextPost: NewTextPost, isEditingPostWithId: string | undefined) {
    const body = {
      text: newTextPost.text === '' ? undefined : newTextPost.text,
      _organization: newTextPost.organizationId,
      privateToOrg: newTextPost.privateToOrg,
      _post: newTextPost.postId,
      imageUrl: newTextPost.imageUrl,
    }

    return await axios.post<NewTextPostResponse>(isEditingPostWithId ? `/api/v2/textposts/${isEditingPostWithId}` : '/api/v2/textposts', body)
  }

  /**
   * Deletes a text post from the server
   * @param id The id of the post to delete
   */
  async deleteTextPost(id: string) {
    return await axios.delete(`/api/v2/textposts/${id}`)
  }

  async reportTextPost(id: string) {
    return await axios.post<EmptyResponse>(`/api/v2/textposts/${id}/report`)
  }

  async likeTextPost(id: string, shouldLike: boolean) {
    return await axios.post<EmptyResponse>('/api/v2/textposts/like', { textpostId: id, status: shouldLike })
  }

  async getTextPostLikes(id: string) {
    return await axios.get<LoadTextPostLikesResponse>(`/api/v2/textposts/likes/${id}`)
  }

  // Image uploading
  /**
   * Creates and returns an image upload request file
   * @param fileName The name of the file
   * @param fileType The type of the file, from Blob.type
   */
  async getImageUploadRequest(fileName: string, fileType: string) {
    const resp = await axios.get(`/api/v2/webimage-s3?fileName=${fileName}&fileType=${fileType}`)
    return resp
  }
}

const shared = new ApiService()

export default shared
