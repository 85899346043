import PropTypes from 'prop-types'

const { string, shape, bool } = PropTypes

export const UserPropType = shape({
  firstName: string,
  lastName: string,
  email: string,
  address: string,
  phone: string,
  country: string,
  city: string,
  state: string,
  zip: string,
  birthday: string,
  imageUrl: string,
  _id: string,
  emailVerified: bool,
  phoneVerified: bool,
  isAdmin: bool,
  userSettings: shape({
    emailOnProjectUpdates: bool,
    emailOnProjectComments: bool,
    emailOnCommentMentions: bool,
    emailOnCommentLike: bool,
    emailList: bool,
    showOnLeaderBoard: bool
  })
})

export const ProjectPropType = shape({
  _id: string.isRequired,
  title: string.isRequired,
  isCompleted: bool,
  isEvent: bool,
  isNational: bool,
  eventDate: string,
  lastModified: string,
  shortName: string.isRequired,
  text: string.isRequired,
  imageUrl: string,
  isApproved: bool.isRequired,
  distanceFromUser: string,
  _creator: shape({
    firstName: string,
    lastName: string,
    email: string
  }),
  _organizationId: shape({
    _id: string,
    name: string,
    shortName: string,
    imageUrl: string
  })
})
