import React from 'react'
import PropTypes from 'prop-types'

class MainContainer extends React.Component {
  render() {
    return (
      <div className='container pb-5' style={{ paddingTop: this.props.noTopMargin ? 54 : 80 }}>
        {this.props.children}
      </div>
    )
  }
}

MainContainer.propTypes = {
  children: PropTypes.element,
  noTopMargin: PropTypes.bool,
}

export default MainContainer
