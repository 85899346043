import Moment from 'moment'
import constants from '../communities/constants'

export function getResizedUrl(url) {
  const parser = document.createElement('a')
  parser.href = url
  parser.hostname = 'magnifybucketresized.s3.amazonaws.com'
  let fileName = url.split('/').pop()
  fileName = 'resized-' + fileName
  parser.pathname = '/' + fileName
  return parser.href
}

export function getResizedImageUrl(url) {
  const filename = url.split('/').pop().split('#')[0].split('?')[0]
  return 'https://magnifybucketresized.s3.amazonaws.com/resized-' + filename
}

export function getResizedWebImageUrl(url) {
  const filename = url.split('/').pop().split('#')[0].split('?')[0]
  return 'https://magnifywebimage-resized.s3.amazonaws.com/resized-' + filename
}

export function getFormGroupClass(state, fieldName) {
  return state[fieldName].valid ? 'form-group is-valid' : 'form-group is-invalid'
}

export function getFormControlClass(state, fieldName) {
  return state[fieldName].valid ? 'form-control is-valid' : 'form-control is-invalid'
}

export function formControl(isValid) {
  return isValid ? 'form-control is-valid' : 'form-control is-invalid'
}

export function getInputGroupClass(state, fieldName) {
  return state[fieldName].valid ? 'input-group is-valid' : 'input-group is-invalid'
}

export function getCustomFormControlClass(state, fieldName) {
  return state[fieldName].valid ? 'form-control custom-control is-valid' : 'form-control custom-control is-invalid'
}

export function getRankFromScore(score) {
  if (constants.NAME.toLowerCase() == 'jbuzzstl') {
    if (score < 20) {
      return 'Wheat'
    } else if (score < 50) {
      return 'Barley'
    } else if (score < 100) {
      return 'Grapes'
    } else if (score < 200) {
      return 'Fig'
    } else if (score < 300) {
      return 'Pomegranate'
    } else if (score < 400) {
      return 'Olive'
    } else {
      return 'Date'
    }
  } else {
    if (score < 20) {
      return 'Junior'
    } else if (score < 100) {
      return 'Rookie'
    } else if (score < 300) {
      return 'Professional'
    } else if (score < 500) {
      return 'Superhero'
    } else {
      return 'Wizard'
    }
  }
}

export function getImageFromScore(score) {
  if (constants.NAME.toLowerCase() == 'jbuzzstl') {
    if (score < 20) {
      return '/com-img/jbuzz/Species-Wheat.png'
    } else if (score < 50) {
      return '/com-img/jbuzz/Species-Barley.png'
    } else if (score < 100) {
      return '/com-img/jbuzz/Species-Grapes.png'
    } else if (score < 200) {
      return '/com-img/jbuzz/Species-Fig.png'
    } else if (score < 300) {
      return '/com-img/jbuzz/Species-Pomegranate.png'
    } else if (score < 400) {
      return '/com-img/jbuzz/Species-Olive.png'
    } else {
      return '/com-img/jbuzz/Species-Date.png'
    }
  } else {
    if (score < 20) {
      return '/com-img/magnify/magnify-junior.png'
    } else if (score < 100) {
      return '/com-img/magnify/magnify-rookie.png'
    } else if (score < 300) {
      return '/com-img/magnify/magnify-professional.png'
    } else if (score < 500) {
      return '/com-img/magnify/magnify-superhero.png'
    } else {
      return '/com-img/magnify/magnify-wizard.png'
    }
  }
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

export function IsUndefined(myVar) {
  return typeof myVar === 'undefined'
}

const REFERENCE = Moment()
const TODAY = REFERENCE.clone().startOf('day')
const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day')
const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day')

export function isToday(momentDate) {
  return momentDate.isSame(TODAY, 'd')
}

export function isYesterday(momentDate) {
  return momentDate.isSame(YESTERDAY, 'd')
}

export function isWithinAWeek(momentDate) {
  return momentDate.isAfter(A_WEEK_OLD)
}

export function isTwoWeeksOrMore(momentDate) {
  return !isWithinAWeek(momentDate)
}

export function getFormattedDate(momentDate) {
  if (isToday(momentDate)) {
    return 'Today @ ' + momentDate.format('h:mm a')
  } else if (isYesterday(momentDate)) {
    return 'Yesterday @ ' + momentDate.format('h:mm a')
  } else if (isWithinAWeek(momentDate)) {
    return momentDate.format('dddd') + ' @ ' + momentDate.format('h:mm a')
  } else {
    return momentDate.format('ddd, MMM Do') + ' @ ' + momentDate.format('h:mm a')
  }
}

function formatDateShort(date) {
  return Moment.utc(date).local().format('MMM D, YYYY') + ' at ' + Moment.utc(date).local().format('h:mm a')
}

/**
 * Formats a date (or start and end date) in a nice short style
 * @param {Date} startDate The start or only date
 * @param {Date} endDate The end date
 */
export function formatDate(startDate, endDate) {
  if (endDate) {
    let endDateFormatted
    const endDateMoment = Moment.utc(endDate).local()

    if (endDateMoment.isSame(Moment.utc(startDate).local(), 'day')) {
      endDateFormatted = endDateMoment.format('h:mm a')
    } else {
      endDateFormatted = formatDateShort(endDate)
    }

    return formatDateShort(startDate) + ' - ' + endDateFormatted
  }

  return formatDateShort(startDate)
}

export const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const ZIPRegex = /^\d{5}(?:[-\s]\d{4})?$/
export const WebsiteRegex = /^[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/

export const MAX_TASK_LENGTH = 500

export function isCommunityApprover(user) {
  if (!user.roles || !user.roles.communityApprover) {
    return false
  }

  return user.roles.communityApprover.includes(constants.NAME.toLowerCase())
}
