import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState, TextPost } from '../model/types'
import TextPostContent from './TextPostContent'
import CommentControl from './project-page/CommentControl'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { isSuccess } from '../model/Util'

export type TextPostModalProps = {
  postId?: string
  post?: TextPost
  show: boolean
  dismiss: () => void
  didDeletePost: (id: string) => void
  didEditPost: (post: TextPost) => void
}

const TextPostModal: React.FC<TextPostModalProps> = (props) => {
  const { post, postId, show, dismiss, didDeletePost, didEditPost } = props

  const [textPost, setTextPost] = React.useState<TextPost | undefined>(post)
  const selectUser = (state: RootState) => state.user
  const user = useSelector(selectUser)

  useEffect(() => {
    let isMounted = true
    if (postId && !post) {
      axios.get(`/api/v2/textpost/${postId}`).then((resp) => {
        if (isMounted && isSuccess(resp.status)) {
          setTextPost(resp.data.data)
        }
      })
    } else {
      setTextPost(post)
    }

    return () => {
      isMounted = false
    }
  }, [postId, post])

  //console.log(`textPost`, textPost)

  return (
    <Modal show={show} size='lg' onHide={dismiss} contentClassName='bg-transparent overflow-visible border-0' dialogClassName='mt-5'>
      <div className='w-100'>
        {textPost && (
          <>
            <TextPostContent post={textPost} isFirst didDeletePost={didDeletePost} didEditPost={didEditPost} showBackButton></TextPostContent>
            <div className='row'>
              <div className='col p-0'>
                <CommentControl userId={user._id} postId={textPost._id} members={[]} _creator={textPost._creator} type='textpost' />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default TextPostModal
