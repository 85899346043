/* eslint-disable import/first */
/* eslint-disable react/prop-types */
import React, { Suspense } from 'react'

// styling
import './styles/animate.css'
import './styles/fonts.css'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import 'jquery'
import 'popper.js'

import 'react-toastify/dist/ReactToastify.css'

import './communities/magnify/theme.scss'

if (window.COMMUNITY) {
  console.log(window.COMMUNITY)

  import(`./communities/${window.COMMUNITY || 'magnify'}/theme.scss`).then((scss) => {
    // We have to import the App.css after so it overrides some stuff
    import('./styles/App.css').then((css) => {
      console.log('Loaded all styles')
    })
  })
}

// import './styles/App.css'
import './styles/offcanvas.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-aspect-ratio/aspect-ratio.css'
import 'animate.css' // this is too new, we'll need to update everywhere that uses it

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import QueryString from 'query-string'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import ReactGA from 'react-ga4'

import store from './redux/store'
import DiscoverPage from './pages/DiscoverPage'
import FeedPage from './pages/FeedPage'
import Analytics from './components/Analytics'
import { ToastContainer } from 'react-toastify'

// Lazy import all the pages we need to keep bundle size small
const LandingWrapper = React.lazy(() => import('./pages/LandingWrapper'))
const Survey = React.lazy(() => import('./pages/Survey'))
const SurveyEditor = React.lazy(() => import('./pages/SurveyEditor'))
const Pending = React.lazy(() => import('./pages/Pending'))
const PendingOrgs = React.lazy(() => import('./pages/PendingOrgsPage'))
const Mine = React.lazy(() => import('./pages/Mine'))
const Leaderboard = React.lazy(() => import('./pages/Leaderboard'))
const CreatePost = React.lazy(() => import('./pages/CreateProjectPage'))
const Memberships = React.lazy(() => import('./pages/Memberships'))
const ProfilePage = React.lazy(() => import('./pages/ProfilePage'))
const UserSettings = React.lazy(() => import('./pages/UserSettings'))
const Organizations = React.lazy(() => import('./pages/Organizations'))
const CreateOrganization = React.lazy(() => import('./pages/CreateOrganizationPage'))
const Login = React.lazy(() => import('./pages/Login'))
const ResetPasswordRequest = React.lazy(() => import('./pages/ResetPasswordRequest'))
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'))
const Register = React.lazy(() => import('./pages/Register'))
const ValidateAccount = React.lazy(() => import('./pages/ValidateAccount'))
const AuthenticateUser = React.lazy(() => import('./pages/AuthenticateUser'))
const TwoFactorAuthentication = React.lazy(() => import('./pages/TwoFactorAuthentication'))
const FinishSignup = React.lazy(() => import('./pages/FinishSignup'))
const Help = React.lazy(() => import('./pages/Help'))
const About = React.lazy(() => import('./pages/About'))
const Pricing = React.lazy(() => import('./pages/Pricing'))
const Feedback = React.lazy(() => import('./pages/Feedback'))
const TermsOfService = React.lazy(() => import('./pages/TermsOfService'))
const SubscriptionTerms = React.lazy(() => import('./pages/SubscriptionTerms'))
const ProjectPage = React.lazy(() => import('./pages/ProjectPage'))
const Organization = React.lazy(() => import('./pages/OrganizationPage'))
const SocialProfile = React.lazy(() => import('./pages/SocialProfilePage'))
const CalendarPage = React.lazy(() => import('./pages/CalendarPage'))
const AddCategoryPage = React.lazy(() => import('./pages/AddCategoryPage'))
const SearchPage = React.lazy(() => import('./pages/SearchPage'))

const FourOhFour = React.lazy(() => import('./pages/FourOhFour'))
const AnalyticsTracker = () => <Route component={Analytics} />

ReactGA.initialize('G-8T992K6635')

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <CookiesProvider>
          <div className='app'>
            <AnalyticsTracker />
            <Suspense fallback={<div />}>
              <Switch>
                <Route exact path='/' component={LandingWrapper} />
                <Route exact path='/home' component={FeedPage} />
                <Route exact path='/home/:id' component={FeedPage} />
                <Route
                  path='/discover'
                  component={(props) => {
                    return <DiscoverPage params={QueryString.parse(props.location.search)} {...props} />
                  }}
                />
                <Route exact path='/search' component={SearchPage} />
                <Route exact path='/survey' component={Survey} />
                <Route exact path='/surveyeditor' component={SurveyEditor} />
                <Route exact path='/pending' component={Pending} />
                <Route exact path='/pendingorgs' component={PendingOrgs} />
                <Route exact path='/mine' component={Mine} />
                <Route exact path='/leaderboard' component={Leaderboard} />
                <Route exact path='/create' component={CreatePost} />
                <Route exact path='/following' component={Memberships} />
                <Route exact path='/calendar' component={CalendarPage} />
                <Route exact path='/profile' component={ProfilePage} />
                <Route exact path='/account' component={UserSettings} />
                <Route exact path='/organizations' component={Organizations} />
                <Route exact path='/organizations/create' component={CreateOrganization} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/forgot' component={ResetPasswordRequest} />
                <Route exact path='/resetpassword' component={ResetPassword} />
                <Route exact path='/validateaccount' component={ValidateAccount} />
                <Route exact path='/authenticateuser' component={AuthenticateUser} />
                <Route exact path='/2fa' component={TwoFactorAuthentication} />
                <Route exact path='/finishsignup' component={FinishSignup} />
                <Route exact path='/help' component={Help} />
                <Route exact path='/about' component={About} />
                <Route exact path='/landing' component={LandingWrapper} />
                <Route exact path='/pricing' component={Pricing} />
                <Route exact path='/feedback' component={Feedback} />
                <Route exact path='/tos' component={TermsOfService} />
                <Route exact path='/subscriptionterms' component={SubscriptionTerms} />
                <Route exact path='/managecategories' component={AddCategoryPage} />
                <Route exact path='/organization/:id' component={Organization} />
                <Route
                  exact
                  path='/register'
                  component={(props) => {
                    return <Register title={'Sign Up'} buttonTitle={'Sign Up'} {...props} />
                  }}
                />
                <Route
                  exact
                  path='/user/:id'
                  component={(props) => {
                    return <SocialProfile username={props.match.params.id} {...props} />
                  }}
                />
                <Route
                  path='/edit/:id'
                  component={(props) => {
                    return <CreatePost editingProjectId={props.match.params.id} {...props} />
                  }}
                />
                <Route
                  path='/organization/edit/:id'
                  component={(props) => {
                    return <CreateOrganization editMode _id={props.match.params.id} {...props} />
                  }}
                />
                <Route path='/404' component={FourOhFour} />
                <Route
                  path='/:id'
                  component={(props) => {
                    return <ProjectPage shortname={props.match.params.id} />
                  }}
                />
                <Route component={FourOhFour} />
              </Switch>
            </Suspense>
            <ToastContainer />
          </div>
        </CookiesProvider>
      </Provider>
    </BrowserRouter>
  )
}

export default App
