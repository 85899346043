import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { UserPropType } from '../model/CustomPropTypes'

/**
 * Redirects to the "finish signup" page if the user is missing a ZIP code.
 */
function CompleteLoginRedirect ({ user }) {
  if (user._id && !user.zip) {
    return <Redirect push to='finishSignup' />
  }

  return null
}

CompleteLoginRedirect.propTypes = {
  user: UserPropType
}

const mapStateToProps = (state) => ({ user: state.user })

export default connect(mapStateToProps)(CompleteLoginRedirect)
