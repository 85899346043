import React from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { RootState } from '../../model/types'

const TextPostsWelcomePost: React.FC = (props) => {
  const [cookies, setCookie] = useCookies(['welcomePostDismisedV2'])
  const user = useSelector((state: RootState) => state.user)

  const hidePost = () => {
    setCookie('welcomePostDismisedV2', true, { path: '/', expires: new Date(2147483647 * 1000) })
  }

  if (cookies.welcomePostDismisedV2) {
    return null
  }

  return (
    <div className='row card rounded-lg mt-3 p-3 d-flex flex-column align-items-start overflow-auto'>
      <div className='font-weight-bold d-flex flex-row w-100'>
        <div>Welcome to your feed!</div>
        <div className='flex-fill' />
        <button type='button' className='close' data-dismiss='alert' aria-label='Close' onClick={hidePost}>
          <span aria-hidden='true'>&times;</span>
        </button>

        {/* <div className='flex-fill' />
        <button type='button' onClick={() => {}} className='closeButton card-shadow' aria-label='Cancel'>
          <span aria-hidden='true'>&times;</span>
        </button> */}
      </div>
      <div className='small' style={{ color: '#BBB' }}>
        Hi {user.firstName}, your home page is your launchpad to engaging with your community.
      </div>
      <div className='d-flex flex-column mt-4'>
        <div className='d-flex flex-row align-items-center p-2'>
          <i className='fa fa-pencil-square-o fa-3x text-primary mr-3' />
          <div className='text-secondary'>
            <span className='font-weight-bold text-primary'>Text Posts</span> let you easily share updates, post pictures, and stay up-to-date with your
            community.
          </div>
        </div>
        <div className='d-flex flex-row align-items-center p-2 mt-3'>
          <i className='fa fa-address-book-o fa-3x text-info mr-3' />
          <div className='text-secondary'>
            You can <span className='font-weight-bold text-info'>Follow Users</span> to build your network, letting you see posts from people who are making a
            difference.
          </div>
        </div>
        <div className='d-flex flex-row align-items-center p-2 mt-3'>
          <i className='fa fa-star fa-3x text-success mr-3' />
          <div className='text-secondary'>
            The{' '}
            <a className='font-weight-bold text-success ' href='/discover'>
              Discover Page
            </a>{' '}
            shows you all the projects in your community for you to join.
          </div>
        </div>
      </div>
      <div className='font-weight-bold pt-3 small'>
        Try making your first post by clicking the box above!
        <span className='text-muted font-weight-normal'> You can dismiss this message whenever you want.</span>
      </div>
    </div>
  )
}

export default TextPostsWelcomePost
