import { SET_SEARCH_TERM, SET_LOGGED_IN_USER } from './actions'

const DEFAULT_STATE = {
  searchTerm: '',
  user: {},
  activeMembershipsHash: {}
}

const setSearchTerm = (state, action) => Object.assign({}, state, { searchTerm: action.payload })
const setLoggedInUser = (state, action) => {
  if (action?.payload?.activeMemberships) {
    // turn the activeMemberships into a hash
    const membershipsHash = action.payload.activeMemberships.reduce(function (map, obj) {
      map[obj._post] = true
      return map
    }, {})
    return Object.assign({}, state, { user: action.payload, activeMembershipsHash: membershipsHash })
  } else {
    return Object.assign({}, state, { user: action.payload })
  }
}

const rootReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return setSearchTerm(state, action)
    case SET_LOGGED_IN_USER:
      return setLoggedInUser(state, action)
    default:
      return state
  }
}

export default rootReducer
