import React from 'react'
import ReactGA from 'react-ga4'
import PropTypes from 'prop-types'

class Analytics extends React.Component {
  constructor(props) {
    super(props)
    // Initial page load - only fired once
    this.sendPageChange(props.location.pathname, props.location.search)
  }

  componentWillReceiveProps(nextProps) {
    // When props change, check if the URL has changed or not
    if (this.props.location.pathname !== nextProps.location.pathname || this.props.location.search !== nextProps.location.search) {
      this.sendPageChange(nextProps.location.pathname, nextProps.location.search)
    }
  }

  sendPageChange(pathname, search = '') {
    const path = pathname + search
    ReactGA.send({ hitType: 'pageview', page: path })
  }

  render() {
    return null
  }
}

const { string, shape } = PropTypes
Analytics.propTypes = {
  location: shape({
    pathname: string,
    search: string,
  }),
}

export default Analytics
