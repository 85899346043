import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import nl2br from 'react-nl2br'
import Linkify from 'linkifyjs/react'
import { Link } from 'react-router-dom'
import Dotdotdot from 'react-dotdotdot'

import { UserPropType } from '../model/CustomPropTypes'

function SmartOrgCard ({ org, handleLeaveOrganization, handleJoinOrganization }) {
  const url = '/organization/' + org.shortName
  const imageUrl = org.imageUrl ? org.imageUrl : '/com-img/magnify/logo-bw.svg'

  return (
    <div className='col-sm-12 col-md-6 mt-2 mb-3' key={org._id}>
      <div className='card rounded-lg h-100'>
        <div className='row h-100 no-gutters'>
          <div className='col-md-4 text-center'>
            <Link className='d-inline-block mt-4 mb-4 rounded' to={url}><img src={imageUrl} alt='Organization Logo' width='128' height='auto' /></Link>
          </div>
          <div className='col-md-8 d-flex'>
            <div className='card-body d-flex flex-column'>
              <Link to={url}><h5 className='card-title'>{org.name}</h5></Link>
              <div className='card-text mb-auto'>
                <Linkify>
                  <Dotdotdot clamp={5}>
                    {nl2br(org.description)}
                  </Dotdotdot>
                </Linkify>
              </div>
              {org.isMember
                ? <button type='button' className='btn btn-light align-self-end mt-2' onClick={() => { handleLeaveOrganization(org._id) }}>Unfollow</button> : org.isPublic
                  ? <button type='button' className='btn btn-success align-self-end mt-2' onClick={() => { handleJoinOrganization(org._id) }}>Follow</button> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SmartOrgCard.propTypes = {
  org: PropTypes.any,
  sanization: PropTypes.func,
  handleLeaveOrganization: PropTypes.func,
  user: UserPropType,
  membershipsHash: PropTypes.any
}

const mapStateToProps = (state) => ({ user: state.user, membershipsHash: state.activeMembershipsHash })

export default connect(mapStateToProps)(SmartOrgCard)
