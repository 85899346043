export default {
  control: {
    fontWeight: 'normal'
  },

  highlighter: {
    overflow: 'hidden'
  },

  input: {
    margin: 0
  },

  '&singleLine': {
    control: {
      display: 'inline-block',

      width: 130
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent'
    },

    input: {
      padding: 1,

      border: '2px inset'
    }
  },

  '&multiLine': {
    control: {
      color: '#555555',
      borderRadius: '0.25rem',
      backgroundPosition: 'center right calc(0.375em + 0.1875rem)',
      backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
      backgroundRepeat: 'no-repeat'
    },

    highlighter: {
      padding: 5,
      border: '1px'
    },

    input: {
      paddingRight: 'calc(1.5em + 0.75rem)',
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
      outline: 0,
      border: '1px'
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      borderRadius: 5,
      minWidth: 200,
      maxHeight: 300,
      overflow: 'auto'
    },

    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',

      '&focused': {
        backgroundColor: '#d9f1fb'
      }
    }
  }
}
