import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import { Nav } from 'react-bootstrap'

import { setLoggedInUser, setSearchTerm } from '../redux/actionCreators'
import ProjectFilters, { displayForFilter } from '../model/ProjectFilters'
import { useReloadUserOnce } from '../redux/useReloadUser'
import CompleteLoginRedirect from '../components/CompleteLoginRedirect'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MainContainer from '../components/MainContainer'
import ProjectsList from '../components/ProjectsList'
import SurveyBanner from '../components/AlertBoxes/SurveyBanner'
import InterestsBanner from '../components/AlertBoxes/InterestsBanner'
import { UserPropType } from '../model/CustomPropTypes'
import { InlineCategoryPicker } from '../components/CategoriesList'
import OrganizationsList from '../components/OrganizationsList'

function DiscoverPage({ params, user, resetSearchTerm, searchTerm, handleLoggedInUser }) {
  const [projects, setProjects] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [feedbackMessage, setFeedbackMessage] = useState(null)
  const [toggleRefresh, setToggleRefresh] = useState(false)
  const [showCompleted, setShowCompleted] = useState(false)

  useReloadUserOnce()

  const filter = params?.filter ?? ProjectFilters.FORYOU

  useEffect(() => {
    resetSearchTerm()
  }, [filter, showCompleted])

  useEffect(() => {
    //console.log('reload')
    loadMoreProjects(filter, searchTerm, true)
  }, [filter, searchTerm, toggleRefresh, showCompleted])

  const loadMoreProjects = async (currentFilter, currentQuery, shouldReset = false) => {
    if (!hasMore && !shouldReset) {
      // No more to get
      return
    }
    setIsLoading(true)

    const skip = shouldReset ? 0 : projects.length

    const projectsResp = await axios.get(`/api/v2/posts?skipItems=${skip}&searchTerm=${currentQuery}&filter=${currentFilter}&showCompleted=${showCompleted}`)
    if (projectsResp.data.success) {
      if (currentFilter !== filter || currentQuery !== searchTerm) {
        // This async task is out-of-date, so we don't want to modify anything
        return
      }

      if (shouldReset) {
        setProjects(projectsResp.data.data)
        setHasMore(true)
        setIsLoading(false)
        return
      }

      if (projectsResp.data.data.length > 0) {
        setProjects([...projects, ...projectsResp.data.data])
      } else {
        setHasMore(false)
      }

      setIsLoading(false)
    } else {
      console.log('Error loading projects')
    }
  }

  const reactivateAccount = async () => {
    const activateResp = await axios.post('/api/v2/me/delete', { isDeleted: false })
    if (!activateResp.data.success) {
      console.log('Failure to re-activate account')
      setFeedbackMessage('Error re-activating account: ' + activateResp.data.error)
    } else {
      window.location.reload()
    }
  }

  const submitInterests = async (pickedIds) => {
    const updateResp = await axios.post('api/me/update', {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      zip: user.zip,
      bio: user.bio,
      interests: pickedIds,
    })

    if (updateResp.data.success) {
      // reload the page
      handleLoggedInUser(updateResp.data.data)
      setToggleRefresh(!toggleRefresh)
    }
  }

  const filterDisplay = displayForFilter(filter)

  if (user.isDeleted) {
    if (user.isDisabledByAdmin) {
      return (
        <div>
          <Header active='home' showHomeControls />
          <MainContainer>
            <div className='row'>
              <div className='col'>
                <h4>Hello, {user.firstName}, your account is temporarily suspended. Please contact support for more information.</h4>
              </div>
            </div>
          </MainContainer>
          <Footer />
        </div>
      )
    }

    // Otherwise, they deleted their own account
    return (
      <div>
        <Header active='home' showHomeControls />
        <MainContainer>
          <div className='row'>
            <div className='col'>
              <h4>Welcome back, {user.firstName}!</h4>
              <p>
                We've missed having you around. Do you wish to re-activate your account? Once you reactivate, you'll be able to participate in projects again.
              </p>
              <button type='button' className='btn btn-success mt-2' onClick={reactivateAccount}>
                Yes, re-activate my account
              </button>
            </div>
          </div>
        </MainContainer>
        <Footer />
      </div>
    )
  }

  if (!user._id) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: '90vh' }}>
        <div className='spinner-grow text-info' style={{ width: '3rem', height: '3rem' }} role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    )
  }

  const needsSetInterests = user && (!user.interests || user.interests.length === 0)

  return (
    <div>
      <CompleteLoginRedirect />
      <Header active='home' showSearchBar />
      <MainContainer noTopMargin>
        <div className='pt-4'>
          {/* {!user.completedSurvey && <SurveyBanner />} */}
          <div className='row mb-1 align-items-center'>
            <div className='col '>
              <h2 className='font-weight-bold'>Discover</h2>
            </div>
            <div className='col-md-12 col-lg-auto mb-2'>
              <Nav variant='pills' defaultActiveKey={`?filter=${filter}`} className='justify-content-end'>
                <Nav.Item>
                  <Nav.Link as={Link} to={`?filter=${ProjectFilters.FORYOU}`} href={`?filter=${ProjectFilters.FORYOU}`}>
                    <i className='fa fa-star mr-2' />
                    For You
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to={`?filter=${ProjectFilters.NEARBY}`} href={`?filter=${ProjectFilters.NEARBY}`}>
                    <i className='fa fa-map-marker mr-2' />
                    Nearby
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to={`?filter=${ProjectFilters.EVENTS}`} href={`?filter=${ProjectFilters.EVENTS}`}>
                    <i className='fa fa-calendar mr-2' />
                    Events
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to={`?filter=${ProjectFilters.NATIONAL}`} href={`?filter=${ProjectFilters.NATIONAL}`}>
                    <i className='fa fa-flag mr-2' />
                    National
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to={`?filter=${ProjectFilters.RECENT}`} href={`?filter=${ProjectFilters.RECENT}`}>
                    <i className='fa fa-clock-o mr-2' />
                    Updated
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to={`?filter=organizations`} href={`?filter=organizations`}>
                    <i className='fa fa-users mr-2' />
                    Organizations
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          {feedbackMessage && (
            <div className='row mt-2 mb-2'>
              <div className='col'>{feedbackMessage}</div>
            </div>
          )}

          {filter == 'recentlyUpdated' && (
            <div className='row col mt-1 mb-2'>
              <div className='form-group custom-control custom-switch ml-auto'>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  id='showCompleted'
                  checked={showCompleted}
                  onChange={(e) => setShowCompleted(e.target.checked)}
                />
                <label className='custom-control-label' htmlFor='showCompleted'>
                  Show Completed Projects and Events
                </label>
              </div>
            </div>
          )}

          {filter == 'recommended' && (
            <div className='text-muted ml-1'>
              Projects and events based on <Link to={`/user/${user.username}`}>your interests</Link> and <Link to={'/organizations'}>organizations</Link> you
              follow
            </div>
          )}

          {needsSetInterests && filter === ProjectFilters.FORYOU && <InlineCategoryPicker submit={submitInterests} />}

          {filter !== 'organizations' ? <ProjectsList projects={projects} emptyMessage=' ' /> : <OrganizationsList />}

          {!isLoading && hasMore && (
            <Waypoint
              onEnter={() => {
                loadMoreProjects(filter, searchTerm, false)
              }}
            />
          )}
          {isLoading && <h5 className='mt-2 pb-4 text-muted text-center'>Loading ...</h5>}
        </div>
      </MainContainer>
      <Footer />
    </div>
  )
}

DiscoverPage.propTypes = {
  params: PropTypes.shape({ filter: PropTypes.string }),
  user: UserPropType,
  resetSearchTerm: PropTypes.func,
  searchTerm: PropTypes.string,
  handleLoggedInUser: PropTypes.func,
}

const mapStateToProps = (state) => ({ searchTerm: state.searchTerm, user: state.user })
const mapDispatchToProps = (dispatch) => ({
  handleLoggedInUser(user) {
    dispatch(setLoggedInUser(user))
  },
  resetSearchTerm() {
    dispatch(setSearchTerm(''))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverPage)
