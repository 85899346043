import axios from 'axios'
import { NewTextPost, FeedItem, TextPost } from './types'
import { isSuccess } from './Util'
import { LoadTextPostsResponse } from './response-types'

export function initialFeedState(): FeedState {
  return { currentItems: [], offset: 0, hasMore: true }
}

export interface FeedState {
  currentItems: FeedItem[]
  offset: number
  hasMore: boolean
}

/**
 * Fetches more text posts from the API, adding them to the current feed state
 * @returns The current feed state combined with new posts
 */
export async function getMorePosts(currentState: FeedState = initialFeedState()): Promise<FeedState> {
  if (!currentState.hasMore) {
    return currentState
  }

  const postResponse = await axios.get<LoadTextPostsResponse>(`/api/v2/me/textposts?skipItems=${currentState.offset}`)
  if (!isSuccess(postResponse.status) || postResponse == null || postResponse.data.textPosts == null) {
    console.log('Error getting posts')
    return { ...currentState, hasMore: false }
  }

  const pageNumber = Math.floor(currentState.offset / 9)

  const numberOfNewPosts = postResponse.data.textPosts.length
  const newPosts: FeedItem[] = postResponse.data.textPosts.map((post) => ({ type: 'textpost', item: post }))

  if (numberOfNewPosts < 9) {
    // This is the last one, add a post at the end
    newPosts.push({ type: 'end-of-feed', item: "You've reached the end of your feed! Follow users or join orgs to get more posts." })
  }
  if (numberOfNewPosts >= 2) {
    newPosts.splice(2, 0, { type: 'recommended-projects', item: pageNumber })
  }
  if (numberOfNewPosts >= 6) {
    newPosts.splice(6, 0, { type: 'nearby-projects', item: pageNumber })
  }

  return {
    currentItems: currentState.currentItems.concat(newPosts),
    offset: currentState.offset + numberOfNewPosts,
    hasMore: numberOfNewPosts == 9,
  }
}

export function getFeedAfterDeletedPost(currentState: FeedState, id: string): FeedState {
  // Find the index of the post
  const index = currentState.currentItems.findIndex((item) => {
    if (item.type == 'textpost') {
      const post = item.item as TextPost
      return post._id === id
    } else {
      return false
    }
  })
  if (index == -1) {
    console.log('Item was not there')
    return currentState
  }

  // We want to know which number page this item was fetched in
  // Each page gets 9 posts, and adds 2 extras
  // Thus, the integer division of the index by 11 gives the number of pages before the current item
  // I.e., an item in the first page, such as index 4, will give 0
  const numberOfPagesBefore = Math.floor(index / 11)
  const numberOfItemsRemaining = numberOfPagesBefore * 11

  return {
    currentItems: currentState.currentItems.slice(0, numberOfItemsRemaining),
    offset: numberOfItemsRemaining,
    hasMore: true,
  }
}
