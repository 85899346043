/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, NavLink, Redirect, useHistory } from 'react-router-dom'
import axios from 'axios'
import { connect } from 'react-redux'
import UserAvatar from 'react-user-avatar'
import clsx from 'clsx'

import { setLoggedInUser } from '../redux/actionCreators'
import CommunityMeta from './CommunityMeta'
import constants from '../communities/constants'
import { useReloadUserOnce } from '../redux/useReloadUser'
import { UserPropType } from '../model/CustomPropTypes'
import { createUseStyles } from 'react-jss'
import { isCommunityApprover } from '../model/Utils'
import CreateTextPostModal from '../components/CreateTextPostModal'

const useStyles = createUseStyles({
  navBarLight: { borderBottom: '1px solid rgba(0,0,0,.125)', backgroundColor: 'white' },
  navBarDark: { borderBottom: '1px solid rgba(0,0,0,.01)', backgroundColor: 'rgba(15, 23, 37, 0.8)' },
  navBarLightText: {
    '& .navbar-nav .nav-link': {
      color: '#fff',
    },
  },
  navBarTransparentBg: {
    background: 'linear-gradient(rgba(15, 23, 37, 0.8), rgba(15, 23, 37, 0.0))',
    borderBottom: '0px',
  },
})

// eslint-disable-next-line react/prop-types
const NavItem = ({ to, children }) => (
  <li className='nav-item'>
    <NavLink to={to} className='nav-link' activeClassName='active'>
      {children}
    </NavLink>
  </li>
)

function Header(props) {
  const { user, isLanding, handleLoggedInUser, transparentBg } = props
  const [redirectPath, setRedirectPath] = useState(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const [showNewPostDialog, setShowNewPostDialog] = useState(false)

  const isSignedIn = !!user?._id
  const isAdmin = user?.isAdmin || user?.managedOrganizations?.length > 0 || isCommunityApprover(user)
  const isSiteAdmin = user?.isAdmin

  const styles = useStyles()

  useReloadUserOnce()

  const history = useHistory()

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const isScrolled = window.scrollY >= 100
      setIsScrolled(isScrolled)
    })
  }, [])

  if (redirectPath) {
    return <Redirect push to={redirectPath} />
  }

  const useDarkAppearance = isLanding && !isScrolled

  const signedInItems = (
    <div className='collapse navbar-collapse' id='navbarCollapse'>
      {/* The left side */}
      <ul className='navbar-nav mr-auto mt-2 mt-lg-0'>
        <NavItem to='/home'>Home</NavItem>
        <NavItem to='/discover'>Discover</NavItem>
        <NavItem to='/calendar'>Calendar</NavItem>
        <NavItem to='/leaderboard'>Leaderboard</NavItem>
      </ul>

      {/* The profile stuff, when on mobile */}
      <ul className='navbar-nav mt-2 mt-lg-0 d-lg-none'>
        <div className='dropdown-divider' />
        <NavItem to={`/user/${user.username}`}>
          <i className='fa fa-user fa-fw mr-2' />
          Profile
        </NavItem>
        <NavItem to='/account'>
          <i className='fa fa-sliders fa-fw mr-2' />
          Account
        </NavItem>
        <NavItem to='/api/v2/logout'>
          <i className='fa fa-sign-out fa-fw mr-2' />
          Sign Out{' '}
        </NavItem>
      </ul>

      {/* The right side */}
      <ul className='navbar-nav mt-2 mt-lg-0'>
        <NavLink
          className='nav-link btn btn-light-secondary rounded-circle d-flex align-items-center justify-content-center p-0 my-auto mr-3'
          to='/search'
          style={{ paddingBottom: 4, height: '2.5rem', width: '2.5rem', lineHeight: 1.5 }}>
          <div className='d-flex m-0 text-dark'>
            <i className='fa fa-search' />
          </div>
        </NavLink>
        {user.emailVerified || user.phoneVerified ? (
          // <NavLink to='/create' className='btn btn-light'>
          //   New Project
          // </NavLink>
          <li className='nav-item dropdown d-none d-lg-block my-auto mr-3'>
            <a
              className='nav-link btn btn-light-secondary rounded-circle d-flex align-items-center justify-content-center p-0'
              href='#'
              id='createDropdownLink'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
              role='button'
              style={{ paddingBottom: 4, height: '2.5rem', width: '2.5rem', lineHeight: 1.5 }}>
              <div className='d-flex h4 m-0 text-dark'>+</div>
            </a>
            <div className='dropdown-menu dropdown-menu-right fade border-0 shadow-lg rounded-lg' aria-labelledby='createDropdownLink'>
              <div className='col pt-2' style={{ paddingLeft: '1rem' }}>
                <h5 className='font-weight-bold'>Create</h5>
              </div>
              <NavLink to='/create' className='dropdown-item' activeClassName='active'>
                <i className='fa fa-columns fa-fw mr-2 text-primary' />
                Project
              </NavLink>
              <button
                onClick={() => {
                  setShowNewPostDialog(true)
                }}
                className='dropdown-item mt-1'>
                <i className='fa fa-pencil fa-fw mr-2 text-info' />
                Text Post
              </button>
              <NavLink to='/organizations/create' className='dropdown-item mt-1' activeClassName='active'>
                <i className='fa fa-users fa-fw mr-2 text-success' />
                Organization
              </NavLink>
            </div>
          </li>
        ) : (
          <NavLink to='/validateaccount' className='btn btn-warning'>
            Validate Account
          </NavLink>
        )}

        {/* Orgs */}
        <li className='nav-item dropdown d-none d-lg-block my-auto mr-2'>
          <a
            className='nav-link btn btn-light-secondary rounded-circle d-flex align-items-center justify-content-center p-0'
            href='#'
            id='orgsDropdownLink'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
            role='button'
            style={{ paddingBottom: 4, height: '2.5rem', width: '2.5rem', lineHeight: 1.5 }}>
            <div className='d-flex m-0 text-dark'>
              <i className='fa fa-users' />
            </div>
          </a>
          <div
            className='dropdown-menu dropdown-menu-right fade border-0 shadow-lg rounded-lg'
            aria-labelledby='orgsDropdownLink'
            style={{ overflow: 'auto', maxHeight: 500 }}>
            <div className='col pt-2' style={{ paddingLeft: '1rem' }}>
              <h5 className='font-weight-bold'>My Orgs</h5>
            </div>
            {user &&
              user.activeOrganizations?.map((org) => (
                <Link to={`/organization/${org._organization.shortName}`} className=' dropdown-item' key={org._organization._id}>
                  <div className='d-flex flex-row align-items-center w-100 py-1 px-2 rounded-lg'>
                    <img
                      src={org._organization.imageUrl ? org._organization.imageUrl : '/com-img/magnify/logo-bw.svg'}
                      alt='Organization Logo'
                      width='30'
                      height='30'
                      style={{ objectFit: 'cover' }}
                      className='rounded mr-3'
                    />
                    <div className='text-dark '>{org._organization.name}</div>
                  </div>
                </Link>
              ))}
            <Link to={`/organizations`} className=' dropdown-item' key='all'>
              <div className='d-flex flex-row align-items-center w-100 py-1 px-1 rounded-lg'>
                <i
                  className='fa fa-th-large mr-3 bg-light rounded-circle d-flex align-items-center justify-content-center card-shadow'
                  style={{ width: 30, height: 30 }}
                />
                <div className='text-dark '>View all</div>
              </div>
            </Link>
          </div>
        </li>

        {/* The user profile icon with options - desktop */}
        <li className='nav-item dropdown d-none d-lg-block'>
          <a
            className='nav-link'
            href='#'
            id='profileDropdownLink'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
            role='button'
            style={{ paddingBottom: 4 }}>
            <UserAvatar size='2.5rem' className='align-middle my-auto' name={user.firstName + ' ' + user.lastName} src={user.imageUrl} />
          </a>
          <div className='dropdown-menu dropdown-menu-right fade border-0 shadow-lg rounded-lg' aria-labelledby='profileDropdownLink'>
            {/* A little info section about the user */}
            <div className='col mt-2 mb-3' style={{ paddingLeft: '1rem' }}>
              <div className='h5 font-weight-bold mb-1'>{user.firstName + ' ' + user.lastName}</div>
              <span className='badge badge-secondary badge-pill score-text'>
                <i className='fa fa-trophy fa-lg mt-1 mb-1' style={{ color: '#f2b424' }} /> {user.runningScore}
              </span>
            </div>
            <div className='dropdown-divider' />
            <NavLink className='dropdown-item' activeClassName='active' to='/following'>
              <i className='fa-regular fa-calendar-check fa-fw mr-2' />
              Following
            </NavLink>
            <NavLink className='dropdown-item' activeClassName='active' to={`/user/${user.username}`}>
              <i className='fa-regular fa-address-card fa-fw mr-2' />
              Profile
            </NavLink>
            <NavLink className='dropdown-item' activeClassName='active' to='/account'>
              <i className='fa-solid fa-user-gear fa-fw mr-2' />
              Account
            </NavLink>

            {isAdmin && (
              <>
                <div className='dropdown-divider' />
                <NavLink className='dropdown-item' activeClassName='active' to='/pending'>
                  <i className='fa fa-spinner fa-fw mr-2' />
                  Pending Projects
                </NavLink>
                {isSiteAdmin && (
                  <>
                    <NavLink className='dropdown-item' activeClassName='active' to='/pendingorgs'>
                      <i className='fa fa-spinner fa-fw mr-2' />
                      Pending Orgs
                    </NavLink>
                    <NavLink className='dropdown-item' activeClassName='active' to='/surveyeditor'>
                      <i className='fa fa-pencil-square-o fa-fw mr-2' />
                      Survey Editor
                    </NavLink>
                    <NavLink className='dropdown-item' activeClassName='active' to='/managecategories'>
                      <i className='fa-solid fa-icons fa-fw mr-2' />
                      Manage Categories
                    </NavLink>
                  </>
                )}
              </>
            )}
            <div className='dropdown-divider' />
            {
              // We need a testing version of the logout button
              process.env.NODE_ENV === 'development' ? (
                <a
                  className='dropdown-item text-primary'
                  onClick={async () => {
                    await axios.get('/api/v2/logout')
                    setRedirectPath('/')
                    handleLoggedInUser({})
                  }}>
                  <i className='fa fa-sign-out fa-fw mr-2' />
                  Sign Out (dev)
                </a>
              ) : (
                <a className='dropdown-item text-primary' href='/api/v2/logout'>
                  <i className='fa fa-sign-out fa-fw mr-2' />
                  Sign Out
                </a>
              )
            }
          </div>
        </li>
      </ul>
    </div>
  )

  const notSignedInItems = (
    <div className='collapse navbar-collapse' id='navbarCollapse'>
      <ul className='navbar-nav mr-auto mt-2 mt-lg-0'>
        {constants.IS_PRIMARY && (
          <>
            <NavItem to='/about'>About Us</NavItem>
            <NavItem to='/pricing'>Pricing</NavItem>

            <li className='nav-item'>
              <a className='nav-link' href='https://blog.magnifyyourvoice.com/blog' target='_blank' rel='noopener noreferrer'>
                Blog
              </a>
            </li>
          </>
        )}
        <NavItem to='/calendar'>Calendar</NavItem>
      </ul>
      <ul className='navbar-nav mt-2 mt-lg-0'>
        <NavItem to='/login'>Log In</NavItem>
        <li className='nav-item'>
          {constants.NAME.toLowerCase() == 'jbuzzstl' ? (
            <NavLink to='/register' className='btn btn-info ml-2'>
              Sign Up
            </NavLink>
          ) : (
            <NavLink to='/register' className='btn btn-success ml-2'>
              Sign Up
            </NavLink>
          )}
        </li>
      </ul>
    </div>
  )

  const logoPath = !useDarkAppearance ? constants.LOGO_PATH_LIGHT ?? constants.LOGO_PATH : constants.LOGO_PATH

  const didPostNewPost = () => {
    history.push('/home')
  }

  return (
    <nav
      className={clsx(
        'navbar',
        'navbar-expand-lg',
        useDarkAppearance ? 'navbar-dark shadow-none' : 'navbar-light',
        'fixed-top',
        useDarkAppearance ? styles.navBarDark : styles.navBarLight,
        transparentBg && useDarkAppearance ? styles.navBarTransparentBg : '',
        transparentBg && useDarkAppearance ? styles.navBarLightText : ''
      )}>
      <div className='container'>
        <CommunityMeta />
        <Link className='navbar-brand' to={isSignedIn ? '/home' : '/'}>
          <img src={logoPath} alt='Site logo' width={constants.LOGO_WIDTH} height={constants.LOGO_HEIGHT} />
          <span className='ml-2'>{constants.HEADER_DISPLAY_NAME}</span>
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarCollapse'
          aria-controls='navbarCollapse'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon' />
        </button>

        {isSignedIn ? signedInItems : notSignedInItems}

        <CreateTextPostModal
          dismiss={() => {
            setShowNewPostDialog(false)
          }}
          save={didPostNewPost}
          show={showNewPostDialog}
        />
      </div>
    </nav>
  )
}

const mapStateToProps = (state) => ({ user: state.user })
const mapDispatchToProps = (dispatch) => ({
  handleLoggedInUser(user) {
    dispatch(setLoggedInUser(user))
  },
})

Header.propTypes = {
  user: UserPropType,
  useTransformingStyle: PropTypes.bool,
  handleLoggedInUser: PropTypes.func,
  isLanding: PropTypes.bool,
  transparentBg: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
